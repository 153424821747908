import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useLogicaPruebasCampo = (muestraID, mineral, pixeles) => {
  console.log("Iniciando useLogicaPruebasCampo:", {
    muestraID,
    mineral,
    pixeles,
  });
  const navigate = useNavigate();
  const [mineralesAMostrar, setMineralesAMostrar] = useState([
    { mineral: "Calcopirita", flag: false },
    { mineral: "Pirita", flag: false },
    { mineral: "Atacamita", flag: false },
    { mineral: "Crisocola", flag: false },
    { mineral: "Chalcantita", flag: false },
    { mineral: "Malaquita", flag: false },
    { mineral: "Covelina", flag: false },
    { mineral: "Bornita", flag: false },
    { mineral: "Calcosina", flag: false },
    { mineral: "Cuprita", flag: false },
    { mineral: "Azurita", flag: false },
  ]);

  const toggleMineralFlag = (mineral) => {
    const newFlagMinerales = mineralesAMostrar.map((flag) => {
      if (flag.mineral === mineral) {
        return { mineral: mineral, flag: !flag.flag };
      } else {
        return flag;
      }
    });
    setMineralesAMostrar(newFlagMinerales);
  };
  const getFlagMineral = (mineral) => {
    try {
      const flag = mineralesAMostrar.find((flag) => flag.mineral === mineral);
      return flag.flag;
    } catch (error) {
      console.error("Error al obtener el flag del mineral:", error);
      return false;
    }
  };

  const [muestra, setMuestra] = useState(null);

  useEffect(() => {
    const buscarMuestra = async () => {
      try {
        const url_muestras =
          "https://kong.viewmineral.cl/persistencia/proyecto/get/" +
          muestraID +
          "/muestra";
        const response = await axios.get(url_muestras);
        console.log(response.data);
        setMuestra(response.data.muestra.analisis);
      } catch (error) {
        console.error("Error al obtener la lista de muestras:", error);
      }
    };

    toggleMineralFlag(mineral);
    buscarMuestra();
  }, [muestraID]);

  const recalcularMuestra = async (accionRecalculo, nuevoClaseMineral) => {
    const url_update_muestra =
      "https://kong.viewmineral.cl/persistencia/muestra/actualizarMineralMuestra";
    try {
      let payload = {
        accion: accionRecalculo,
        mineralDestino: nuevoClaseMineral,
        mineralOrigen: mineral,
        pixelesOrigen: pixeles,
        muestra_id: muestraID,
        usuario: localStorage.getItem("username"),
      };

      console.log("actualizarMineralMuestra payload::", payload);

      const response = await axios.post(url_update_muestra, payload);
      console.log(response.data);
      alert("Muestra actualizada correctamente");
      navigate("/muestra/detalle/" + muestraID);
    } catch (error) {
      alert("Error al actualizar la muestra");
      console.error("Error al obtener la lista de muestras:", error);
    }
  };

  return {
    muestra,
    recalcularMuestra,
    getFlagMineral,
  };
};

export default useLogicaPruebasCampo;
