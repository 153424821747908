import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";
import SidebarMenu from "./SidebarMenu";
import HelpButton from "./HelpButton";
import GuideButton from "../GuideButton";

// import useBuscarUsuarioLogeado from '../../hooks/useBuscarUsuarioLogeado';

const Navbar = ({ logout }) => {

  const usuarioNulo = {
    nombre: "",
    apellido: "",
    role: ""
  }


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user")) || usuarioNulo;

  // const {validarUsuarioLogeado} = useBuscarUsuarioLogeado();

  // validarUsuarioLogeado();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="sticky top-0 bg-gray-800 z-10">
      <div className="headerTop">
        <IconContext.Provider
          value={{
            color: "white",
            size: "1.5em",
            className: "global-class-name",
          }}
        >
          <div className="topItem">
            <li onClick={toggleSidebar}>
              <FaBars className="SendMenu" />
            </li>
          </div>
          <div className="topItem">
            <a href="/projects">
              <img className="opacity-85" src="/img/logo2022.png" alt="Logo" />
            </a>
          </div>
          <div className="topItem">
            <div className="flex min-w-0 gap-x-4">
              {/* <img className="h-12 w-12 flex-none rounded-full bg-white-50" src={avatarClient} alt="" /> */}
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-white">
                  {user.nombre + " " + user.apellido}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-white">
                  {user.role}
                </p>
              </div>
            </div>
          </div>
        </IconContext.Provider>
        {isSidebarOpen && (
          <SidebarMenu
            isOpen={isSidebarOpen}
            toggleMenu={toggleSidebar}
            logout={logout}
          />
        )}
        <GuideButton />
        <HelpButton />

      </div>
    </div>
  );
};

export default Navbar;
