import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbar";
import Spinner from "../Spinner";
import { FaRedo } from "react-icons/fa";
import Modal from "react-modal";

// Importa las imágenes
import malaquitaImage1 from "./Img/Malaquita_1.jpg";
import malaquitaImage2 from "./Img/Malaquita_2.jpg";
import malaquitaImage3 from "./Img/Malaquita_3.jpg";
import atacamitaImage1 from "./Img/Atacamita_1.jpg";
import atacamitaImage2 from "./Img/Atacamita_2.jpg";
import atacamitaImage3 from "./Img/Atacamita_3.jpg";
import chalcantitaImage1 from "./Img/Chalcantita_1.jpg";
import chalcantitaImage2 from "./Img/Chalcantita_2.jpg";
import chalcantitaImage3 from "./Img/Chalcantita_3.jpg";
import crisocolaImage1 from "./Img/Crisocola_1.jpg";
import crisocolaImage2 from "./Img/Crisocola_2.jpg";
import crisocolaImage3 from "./Img/Crisocola_3.jpg";

import DeteccionPorCapa from "../muestra/DeteccionesPorCapa";
import useLogicaPruebasCampo from "../../hooks/useLogicaPruebasCampo";

// Configuración de react-modal
Modal.setAppElement("#root");

function FormPruebadeCampoMalaquitaAtacamitaChalcantitaCrisocola() {
  const { projectId, processId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { clase, pixeles } = location.state || {};

  console.log("Prueba Campos para Muestra::", processId);
  const { muestra, recalcularMuestra, getFlagMineral } = useLogicaPruebasCampo(
    processId,
    clase,
    pixeles
  );

  const url_get_project = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/registro`;
  const url_put_project_update = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/actualizar`;
  const url_post_project_create =
    "https://kong.viewmineral.cl/persistencia/proyecto/crear";
  const url_nav_to_project = "/project/" + projectId;

  const url_muestras =
    "https://kong.viewmineral.cl/persistencia/proyecto/proceso/" +
    processId +
    "/muestras";
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    descripcion: "",
    usuario: "",
  });
  const [muestras, setMuestras] = useState([]);
  const [detecciones, setDetecciones] = useState([]);
  const [selectedOption, setSelectedOption] = useState(clase || "");
  const [decision, setDecision] = useState({
    pruebaQuimica: null,
    habito: null,
    mineral: null,
    resultado: null,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    const fetchMuestras = async () => {
      try {
        const response = await axios.get(url_muestras);
        console.log(response.data);
        setMuestras(response.data.muestras);
      } catch (error) {
        console.error("Error al obtener la lista de muestras:", error);
      }
    };

    fetchMuestras();
  }, [processId, url_muestras]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleDecisionChange = (e) => {
    const { name, value } = e.target;
    setDecision({
      ...decision,
      [name]: value,
    });
  };

  useEffect(() => {
    evaluateDecisionTree();
  }, [decision.pruebaQuimica, decision.habito, decision.mineral]);

  const evaluateDecisionTree = () => {
    let result = null;

    if (decision.pruebaQuimica === "SI") {
      if (decision.habito === "SI") {
        result = "Malaquita";
      } else if (decision.habito === "NO") {
        result = "Falso Positivo";
      }
    } else if (decision.pruebaQuimica === "NO") {
      if (decision.habito === "NO") {
        result = "Falso Positivo";
      } else {
        result = decision.mineral;
      }
    }

    setDecision((prevDecision) => ({
      ...prevDecision,
      resultado: result,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", form);
    // Agrega aquí la lógica para enviar el formulario
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setDecision({
      pruebaQuimica: null,
      habito: null,
      mineral: null,
      resultado: null,
    });
  };

  const handleReset = () => {
    setForm({ nombre: "", descripcion: "", usuario: "" });
    setSelectedOption("");
    setDecision({
      pruebaQuimica: null,
      habito: null,
      mineral: null,
      resultado: null,
    });
    navigate(-1);
  };

  const openModal = (image) => {
    setModalImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage(null);
  };

  const handleRecalculate = () => {
    setLoading(true);
    // Lógica para recalcular la ley de cobre con los nuevos resultados
    console.log("Recalculando la ley de cobre...");
    console.log("Decision:", decision);
    recalcularMuestra("fusionar", decision.resultado);
    setLoading(false);
  };

  return (
    <div>
      <Navbar />

      {loading && <Spinner />}
      <div className="px-8 pt-6 pb-8 mb-4">
        <h1 className="text-2xl font-bold">
          Prueba de Campo {clase ? `para ` : ""}
          {clase && <span style={{ color: "Blue" }}>{clase}</span>}
        </h1>
        <div className="flex flex-col p-5 h-[300px] md:h-[400px]">
          {muestra && (
            <DeteccionPorCapa
              analisis={muestra}
              getFlagMineral={getFlagMineral}
            />
          )}
        </div>
        <form className="mt-3" onSubmit={handleSubmit}>
          {selectedOption === "Malaquita" ||
          selectedOption === "Atacamita" ||
          selectedOption === "Chalcantita" ||
          selectedOption === "Crisocola" ? (
            <>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="pruebaQuimica"
                >
                  Prueba Química:
                </label>
                <select
                  name="pruebaQuimica"
                  value={decision.pruebaQuimica || ""}
                  onChange={handleDecisionChange}
                  className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                >
                  <option value="" disabled>
                    Seleccione
                  </option>
                  <option value="SI">Sí</option>
                  <option value="NO">No</option>
                </select>
              </div>
              {decision.pruebaQuimica === "SI" && (
                <>
                  <div className="mb-4">
                    <p className="text-gray-700 text-sm mb-2">
                      Según tu elección parece que ser que el material
                      corresponde a <b>Malaquita</b>, sin embargo necesitamos
                      validar que lo que estás viendo corresponde a este
                      mineral, para ello te mostramos las siguientes imágenes
                      como ayuda a identificar. Si consideras que la imagen es
                      lo que ves en la muestra, marca <b>SI</b> en Hábito de lo
                      contrario, marca <b>NO</b>.
                    </p>
                  </div>

                  <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <p className="text-gray-700 text-sm mb-2">
                      <b>MALAQUITA</b>
                    </p>
                    <img
                      src={malaquitaImage1}
                      alt="Malaquita 1"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(malaquitaImage1)}
                    />
                    <img
                      src={malaquitaImage2}
                      alt="Malaquita 2"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(malaquitaImage2)}
                    />
                    <img
                      src={malaquitaImage3}
                      alt="Malaquita 3"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(malaquitaImage3)}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="habito"
                    >
                      Hábito:
                    </label>
                    <select
                      name="habito"
                      value={decision.habito || ""}
                      onChange={handleDecisionChange}
                      className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                    >
                      <option value="" disabled>
                        Seleccione
                      </option>
                      <option value="SI">Sí</option>
                      <option value="NO">No</option>
                    </select>
                  </div>
                </>
              )}
              {decision.pruebaQuimica === "NO" && (
                <>
                  <div className="mb-4">
                    <p className="text-gray-700 text-sm mb-2">
                      Según tu elección, te presentamos imágenes de{" "}
                      <b>Atacamita</b>, <b>Chalcantita</b> y <b>Crisocola</b>.
                      Identifica cuál corresponde a tu muestra. Si consideras
                      que la imagen es lo que ves en la muestra, selecciona la
                      opción correspondiente.
                    </p>
                  </div>

                  <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <p className="text-gray-700 text-sm mb-2">
                      <b>ATACAMITA</b>
                    </p>
                    <img
                      src={atacamitaImage1}
                      alt="Atacamita 1"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(atacamitaImage1)}
                    />
                    <img
                      src={atacamitaImage2}
                      alt="Atacamita 2"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(atacamitaImage2)}
                    />
                    <img
                      src={atacamitaImage3}
                      alt="Atacamita 3"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(atacamitaImage3)}
                    />
                    <p className="text-gray-700 text-sm mb-2">
                      <b>CHALCANTITA</b>
                    </p>
                    <img
                      src={chalcantitaImage1}
                      alt="Chalcantita 1"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(chalcantitaImage1)}
                    />
                    <img
                      src={chalcantitaImage2}
                      alt="Chalcantita 2"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(chalcantitaImage2)}
                    />
                    <img
                      src={chalcantitaImage3}
                      alt="Chalcantita 3"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(chalcantitaImage3)}
                    />
                    <p className="text-gray-700 text-sm mb-2">
                      <b>CRISOCOLA</b>
                    </p>
                    <img
                      src={crisocolaImage1}
                      alt="Crisocola 1"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(crisocolaImage1)}
                    />
                    <img
                      src={crisocolaImage2}
                      alt="Crisocola 2"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(crisocolaImage2)}
                    />
                    <img
                      src={crisocolaImage3}
                      alt="Crisocola 3"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(crisocolaImage3)}
                    />
                  </div>
                </>
              )}

              {decision.pruebaQuimica === "NO" && (
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="mineral"
                  >
                    Hábito
                  </label>
                  <select
                    name="mineral"
                    value={decision.mineral || ""}
                    onChange={handleDecisionChange}
                    className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                  >
                    <option value="" disabled>
                      Seleccione
                    </option>
                    <option value="Atacamita">Atacamita</option>
                    <option value="Chalcantita">Chalcantita</option>
                    <option value="Crisocola">Crisocola</option>
                  </select>
                </div>
              )}
              {decision.resultado && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Resultado:
                  </label>
                  <p className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 py-2 px-3">
                    {decision.resultado}
                  </p>
                </div>
              )}
              {decision.resultado && (
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={() => {
                      handleRecalculate();
                    }}
                    className="bg-green-500 hover:bg-green-700 text-white py-1
                    px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Guardar Resultado
                  </button>
                </div>
              )}
            </>
          ) : (
            selectedOption && (
              <div className="mb-4">
                <p className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 py-2 px-3">
                  No hay Pruebas de Campo definidas para esta elección
                </p>
              </div>
            )
          )}
          <div className="flex items-center justify-between">
            <button
              className="bg-red-500 hover:bg-red-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
              type="button"
              onClick={handleReset}
            >
              Volver atrás
              <FaRedo />
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-red-500"
        >
          Close
        </button>
        {modalImage && (
          <img src={modalImage} alt="Modal Image" className="w-full h-auto" />
        )}
      </Modal>
    </div>
  );
}

export default FormPruebadeCampoMalaquitaAtacamitaChalcantitaCrisocola;
