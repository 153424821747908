import MascarasPorCapa from "./MascarasPorCapa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

function ListaDetecciones({ resultado, toggleMineralFlag, flagMinerales }) {
  console.log("resultado mostrando:", resultado);

  const navigate = useNavigate();

  // Configuración del carrusel
  // Define la función handleButtonClick
  const handleButtonClick = (clase, processId, pixeles) => {
    let url;
    switch (clase) {
      case "Malaquita":
      case "Atacamita":
      case "Chalcantita":
      case "Crisocola":
        url = `/project/process/${processId}/pruebadecampo4`;
        break;
      case "Calcosina":
        url = `/project/process/${processId}/pruebadecampo6`;
        break;
      case "Cuprita":
        url = `/project/process/${processId}/pruebadecampo5`;
        break;
      case "Azurita":
      case "Bornita":
      case "Covelina":
        url = `/project/process/${processId}/pruebadecampoazu`;
        break;
      case "Calcopirita":
      case "Pirita":
        url = `/project/process/${processId}/pruebadecampo2`;
        break;
      default:
        url = `/project/process/${processId}`;
        break;
    }
    navigate(url, { state: { clase, pixeles } });
  };

  return (
    // generar un div con 1 columna y 2 filas
    // en la primera fila mostrar la imagen de la muestra
    // en la segunda fila mostrar la tabla de detecciones

    <table className="text-center">
      <thead>
        <tr>
          <th>Mineral</th>
          <th>Ley de cobre</th>
          <th>Ver</th>
          <th>Prueba de campo</th>
        </tr>
      </thead>
      <tbody>
        {resultado.analisis.detecciones &&
          resultado.analisis.detecciones.map(
            (deteccion, index) =>
              (deteccion.ley >= 0.3 || deteccion.clase === "Pirita") && (
                <tr key={index}>
                  <td>{deteccion.clase}</td>
                  <td>{deteccion.ley}%</td>
                  <td>
                    {flagMinerales.map((flag) => {
                      if (flag.mineral === deteccion.clase) {
                        return (
                          <input
                            type="checkbox"
                            checked={flag.flag}
                            onChange={() => toggleMineralFlag(deteccion.clase)}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </td>
                  <td>
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white text-xs py-1 px-2 rounded"
                      onClick={() =>
                        handleButtonClick(
                          deteccion.clase,
                          resultado._id,
                          deteccion.pixeles
                        )
                      }
                    >
                      IR
                    </button>
                  </td>
                </tr>
              )
          )}
      </tbody>
    </table>
  );
}

export default ListaDetecciones;
