import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbar";
import Spinner from "../Spinner";
import { FaBackward, FaForward, FaRegSave, FaSave } from "react-icons/fa";

function FormPruebadeCampo() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const url_get_project = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/registro`;
  const url_put_project_update = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/actualizar`;
  const url_post_project_create =
    "https://kong.viewmineral.cl/persistencia/proyecto/crear";
  const url_nav_to_project = "/project/" + projectId;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    descripcion:  "",
    usuario: "",
  });

  const { processId } = useParams();
  const url_muestras =
    "https://kong.viewmineral.cl/persistencia/proyecto/proceso/" +
    processId +
    "/muestras";
  const [muestras, setMuestras] = useState([]);
  const [detecciones, setDetecciones] = useState([]);
  useEffect(() => {
    const fetchMuestras = async () => {
      try {
        const response = await axios.get(url_muestras);
        console.log(response.data);
        setMuestras(response.data.muestras);
      } catch (error) {
        console.error("Error al obtener la lista de muestras:", error);
      }
    };

    fetchMuestras();
  }, [processId, url_muestras]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", form);
    // Agrega aquí la lógica para enviar el formulario
  };

  return (
    <div>
      <Navbar />

      {loading && <Spinner />}
      <div className="px-8 pt-6 pb-8 mb-4">
        <h1 className="text-2xl font-bold">Prueba de Campo</h1>
          <form className="mt-3" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="nombre"
              >
                Nombre:
              </label>
              <input
                className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                type="text"
                name="nombre"
                required
                value={form.nombre}
                onChange={handleChange}
              />
            </div>
            <select>
            {muestras &&
              muestras.map((muestra, index) => (
                muestra.analisis.detecciones &&
                muestra.analisis.detecciones.map((deteccion, index) => (
                <option>{deteccion.clase}</option>
                ))
              ))}
             </select>
             

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="descripcion"
              >
                Descripción:
              </label>
              <input
                className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                type="text"
                name="descripcion"
                required
                value={form.descripcion}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="usuario"
              >
                Usuario:
              </label>
              <input
                className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                type="text"
                name="usuario"
                value={form.usuario}
                onChange={handleChange}
                disabled
              />
            </div>
            {projectId && (
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="submit"
                >
                  Actualizar&nbsp;
                  <FaSave />
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="button"
                  onClick={() => navigate(url_nav_to_project)}
                >
                  Volver &nbsp;
                  <FaForward />
                </button>
              </div>
            )}
            {!projectId && (
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="submit"
                >
                  Crear&nbsp;
                  <FaSave />
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="button"
                  onClick={() => navigate("/projects")}
                >
                  Volver al Listado&nbsp;
                  <FaBackward />
                </button>
              </div>
            )}
          </form>
        
      </div>
    </div>
  );
}

export default FormPruebadeCampo;
