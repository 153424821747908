import React, { useState } from "react";
import useBuscarUsuarioLogeado from "../../hooks/useBuscarUsuarioLogeado";
import Spinner from "../Spinner";
import { FaKey } from "react-icons/fa";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { login, mensajeValidacion, loading } =
    useBuscarUsuarioLogeado();

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(username, password);
  };



  return (
    <div className="login-form">
      {loading && <Spinner />}

      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="flex justify-center mb-6">
          <img src="./img/logo-iso.svg" alt="login" className="w-24" />
        </div>
        <h3 className="mb-6 text-center text-xl font-bold text-gray-700">
          Bienvenido a View Mineral
        </h3>
        <div className="mb-4">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Tu correo"
          />
        </div>
        <div className="mb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Contraseña"
          />
        </div>
        <div className="mb-6">
          <button
            className="w-full bg-teal-600 hover:bg-teal-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            <FaKey className="inline-block mr-2" />
            Iniciar Sesión
          </button>
        </div>
        {mensajeValidacion && (
          <div
            className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">{mensajeValidacion}</span>
          </div>
        )}
        {mensajeValidacion && (
          <div
            className="mt-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Información</strong>
            <span className="block sm:inline">
              En caso de expiración o problemas de su credencial contactar a{" "}
              <a href="mailto:info@viewmineral.cl" className="underline  font-bold ">info@viewmineral.cl</a>
            </span>
          </div>
        )}
      </form>
    </div>
  );
};

export default Login;
