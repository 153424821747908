import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbar";
import Spinner from "../Spinner";
import {
  FaBackward,
  FaForward,
  FaRegSave,
  FaSave,
  FaRedo,
} from "react-icons/fa";
import Modal from "react-modal";

// Importa las imágenes
import piritaImage1 from "./Img/Pirita_Habito_1.jpg";
import piritaImage2 from "./Img/Pirita_Habito_2.jpg";
import piritaImage3 from "./Img/Pirita_Habito_3.jpg";
import calcopiritaImage_1 from "./Img/Calcopirita_Habito_1.jpg";
import calcopiritaImage_2 from "./Img/Calcopirita_Habito_2.jpg";
import calcopiritaImage_3 from "./Img/Calcopirita_Habito_3.jpg";
import calcopirita_raya_Image from "./Img/Raya_Calcopirita.jpg";

import DeteccionPorCapa from "../muestra/DeteccionesPorCapa";
import useLogicaPruebasCampo from "../../hooks/useLogicaPruebasCampo";

// Configuración de react-modal
Modal.setAppElement("#root");

function FormPruebadeCampo2() {
  const { processId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { clase, pixeles } = location.state || {};

  console.log("Prueba Campos para Muestra::", processId);
  const { muestra, recalcularMuestra, getFlagMineral } = useLogicaPruebasCampo(
    processId,
    clase,
    pixeles
  );

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    descripcion: "",
    usuario: "",
  });

  const [selectedOption, setSelectedOption] = useState(clase || "");
  const [decision, setDecision] = useState({
    dureza: null,
    habito: null,
    raya: null,
    resultado: null,
  });
  const [finalDecision, setFinalDecision] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleDecisionChange = (e) => {
    const { name, value } = e.target;
    setDecision({
      ...decision,
      [name]: value,
    });
  };

  useEffect(() => {
    if (selectedOption === "Calcopirita" || selectedOption === "Pirita") {
      evaluateDecisionTree();
    }
  }, [decision.dureza, decision.habito, decision.raya, selectedOption]);

  const evaluateDecisionTree = () => {
    let result = null;

    if (decision.dureza === "S") {
      if (decision.habito === "S") {
        result = "Pirita";
      } else if (decision.habito === "N") {
        result = "Falso Positivo";
      }
    } else if (decision.dureza === "N") {
      if (decision.habito === "N") {
        result = "Falso Positivo";
      } else if (decision.habito === "S") {
        if (decision.raya === "S") {
          result = "Calcopirita";
        } else if (decision.raya === "N") {
          result = "Falso Positivo";
        }
      }
    }

    setDecision((prevDecision) => ({
      ...prevDecision,
      resultado: result,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", form);
    // Agrega aquí la lógica para enviar el formulario
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setDecision({
      dureza: null,
      habito: null,
      raya: null,
      resultado: null,
    });
    setFinalDecision(null); // Reset final decision
  };

  const handleReset = () => {
    setForm({ nombre: "", descripcion: "", usuario: "" });
    setSelectedOption("");
    setDecision({ dureza: null, habito: null, raya: null, resultado: null });
    setFinalDecision(null); // Reset final decision
    navigate(-1);
  };

  const openModal = (image) => {
    setModalImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage(null);
  };

  const handleFinalDecision = () => {
    if (decision.resultado === selectedOption) {
      setFinalDecision("correct");
    } else {
      setFinalDecision("incorrect");
    }
  };

  const handleRecalculate = () => {
    setLoading(true);
    // Lógica para recalcular la ley de cobre con los nuevos resultados
    console.log("Recalculando la ley de cobre...");
    console.log("Decision:", decision);
    recalcularMuestra("fusionar", decision.resultado);
    setLoading(false);
  };

  return (
    <div>
      <Navbar />

      {loading && <Spinner />}
      <div className="px-8 pt-6 pb-8 mb-4">
        <h1 className="text-2xl font-bold">
          Prueba de Campo {clase ? `para ` : ""}
          {clase && <span style={{ color: "Blue" }}>{clase}</span>}
        </h1>

        <div className="flex flex-col p-5 h-[300px] md:h-[400px]">
          {muestra && (
            <DeteccionPorCapa
              analisis={muestra}
              getFlagMineral={getFlagMineral}
            />
          )}
        </div>

        <form className="mt-3" onSubmit={handleSubmit}>
          {selectedOption === "Calcopirita" || selectedOption === "Pirita" ? (
            <>
              <div className="mb-4">
                <p className="text-gray-700 text-sm mb-2">
                  Para medir la dureza debe rayar el mineral con un clavo, si se
                  raya marca NO, si no se raya marca SI.
                </p>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="dureza"
                >
                  Dureza:
                </label>
                <select
                  name="dureza"
                  value={decision.dureza || ""}
                  onChange={handleDecisionChange}
                  className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                >
                  <option value="" disabled>
                    Seleccione
                  </option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </select>
              </div>
              {decision.dureza === "S" && (
                <>
                  <div className="mb-4">
                    <p className="text-gray-700 text-sm mb-2">
                      Según tu elección parece que ser que el material
                      corresponde a <b>Pirita</b>, sin embargo necesitamos
                      validar que lo que estás viendo corresponde a este
                      súlfuro, para ello te mostramos las siguiente imagen como
                      ayuda a identificar (pinchalas para verlas).
                      <br />
                      Si consideras que la imagen es lo que ves en la muestra,
                      marca <b>SI</b> en Hábito de lo contrario, marca <b>NO</b>
                      .
                    </p>
                  </div>

                  <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <img
                      src={piritaImage1}
                      alt="Pirita 1"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(piritaImage1)}
                    />
                    <img
                      src={piritaImage2}
                      alt="Pirita 2"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(piritaImage2)}
                    />
                    <img
                      src={piritaImage3}
                      alt="Pirita 3"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(piritaImage3)}
                    />
                  </div>
                </>
              )}
              {decision.dureza === "N" && (
                <>
                  <div className="mb-4">
                    <p className="text-gray-700 text-sm mb-2">
                      Según tu elección parece que ser que el material
                      corresponde a <b>Calcopirita</b>, sin embargo necesitamos
                      validar que lo que estás viendo corresponde a este
                      mineral, para ello te mostramos la siguiente imagen como
                      ayuda a identificar. Si consideras que la imagen es lo que
                      ves en la muestra, marca <b>SI</b> en Hábito de lo
                      contrario, marca <b>NO</b>.
                    </p>
                  </div>

                  <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <img
                      src={calcopiritaImage_1}
                      alt="Calcopirita 1"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(calcopiritaImage_1)}
                    />
                    <img
                      src={calcopiritaImage_2}
                      alt="Calcopirita 2"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(calcopiritaImage_2)}
                    />
                    <img
                      src={calcopiritaImage_3}
                      alt="Calcopirita 3"
                      className="w-full h-32 object-cover cursor-pointer"
                      onClick={() => openModal(calcopiritaImage_3)}
                    />
                  </div>
                </>
              )}
              {decision.dureza && (
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="habito"
                  >
                    Hábito:
                  </label>
                  <select
                    name="habito"
                    value={decision.habito || ""}
                    onChange={handleDecisionChange}
                    className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                  >
                    <option value="" disabled>
                      Seleccione
                    </option>
                    <option value="S">Sí</option>
                    <option value="N">No</option>
                  </select>
                </div>
              )}
              {decision.habito &&
                decision.dureza === "S" &&
                decision.habito === "S" && (
                  <>
                    <div className="mb-4">
                      <p className="text-gray-700 text-sm mb-2">
                        Según lo que has ingresado, el resultado entonces es:
                      </p>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Resultado:
                        </label>
                        <p className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 py-2 px-3">
                          {decision.resultado}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              {decision.habito &&
                decision.dureza === "N" &&
                decision.habito === "S" && (
                  <div className="mb-4">
                    <p className="text-gray-700 text-sm mb-2">
                      Según lo que has ingresado como respuesta necesitamos una
                      prueba mas para confirmar que se trata de{" "}
                      <b>Calcopirita</b>, Para ello te mostraremos la siguiente
                      imagen que representa la prueba de "Raya", para ello debes
                      rayar con el mineral una superficie cerámica, si la raya
                      se parece a la siguiente imagen, entonces marca <b>SI</b>{" "}
                      en Raya, de lo contario marca <b>NO</b>.
                    </p>
                    <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <img
                        src={calcopirita_raya_Image}
                        alt="Calcopirita 1"
                        className="w-full h-32 object-cover cursor-pointer"
                        onClick={() => openModal(calcopirita_raya_Image)}
                      />
                    </div>
                    <div className="mb-4"></div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="raya"
                    >
                      Raya:
                    </label>
                    <select
                      name="raya"
                      value={decision.raya || ""}
                      onChange={handleDecisionChange}
                      className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                    >
                      <option value="" disabled>
                        Seleccione
                      </option>
                      <option value="S">Sí</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                )}
              {decision.resultado &&
                !(decision.dureza === "S" && decision.habito === "S") && (
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Resultado:
                    </label>
                    <p className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 py-2 px-3">
                      {decision.resultado}
                    </p>
                  </div>
                )}
              {decision.resultado && (
                <div className="mb-4">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                    type="button"
                    onClick={handleFinalDecision}
                  >
                    Verificar Resultado
                  </button>
                </div>
              )}
              {finalDecision === "correct" && (
                <div className="mb-4">
                  <p className="text-green-500 text-sm">
                    <b>
                      Según lo analizado se corrobora lo identificado por la
                      Aplicación.
                    </b>
                  </p>
                </div>
              )}
              {finalDecision === "incorrect" && (
                <div className="mb-4">
                  <p className="text-red-500 text-sm">
                    Las pruebas de campo indican que el mineral no es el
                    identificado por la Aplicación.{" "}
                    <b>
                      ¿Quieres que el sistema recalcule la ley de cobre con
                      estos resultados?{" "}
                    </b>
                    <br />
                  </p>
                  <div className="flex items-center space-x-4">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={handleRecalculate}
                    >
                      Sí
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={() => setFinalDecision(null)}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            selectedOption && (
              <div className="mb-4">
                <p className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 py-2 px-3">
                  No hay Pruebas de Campo definidas para esta elección
                </p>
              </div>
            )
          )}
          <div className="flex items-center justify-between">
            <button
              className="bg-red-500 hover:bg-red-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
              type="button"
              onClick={handleReset}
            >
              Volver atrás
              <FaRedo />
            </button>
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-red-500"
        >
          Close
        </button>
        {modalImage && (
          <img src={modalImage} alt="Modal Image" className="w-full h-auto" />
        )}
      </Modal>
    </div>
  );
}

export default FormPruebadeCampo2;
