import React from 'react';

const Slide1 = () => {
  return (
    <div className="slide" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
     
      <img src="./img/welcome-1.svg" alt="Slide 1"  style={{width: '150px'}} />
      <h3 style={{marginBottom: '0', fontWeight: '600'}}><span style={{color: '#1f73b1'}}>CREA</span> UN PROYECTO</h3>
      <p style={{marginTop: '0'}}>Esta aplicación te permite...</p>
    </div>
  );
};

export default Slide1;
