const cssStyleAbsoluteImagen =
  "absolute top-0 left-0 object-cover md:h-96 md:w-100 h-92";

const CapaImagen = ({ deteccion, index }) => {
  return (
    <img
      index={index}
      src={deteccion.img_inferencia}
      alt={deteccion.clase}
      class={cssStyleAbsoluteImagen}
    />
  );
};

function MascarasPorCapa({ resultado, getFlagMineral }) {
  console.log("resultado mostrando:", resultado);

  return (
    <div className="relative">
      <img
        src={resultado.analisis.link_imagen_entrada}
        alt={resultado.analisis.link_imagen_entrada}
        class={cssStyleAbsoluteImagen}
      />
      {resultado.analisis.detecciones &&
        resultado.analisis.detecciones.map((deteccion, index) => {
          if ( (getFlagMineral(deteccion.clase) && deteccion.ley >= 0.3 ) || deteccion.clase === "Pirita") {
            return (
              <CapaImagen key={index} deteccion={deteccion} index={index} />
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default MascarasPorCapa;
