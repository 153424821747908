import { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowRight, FaBraille, FaUpload } from "react-icons/fa";

import Spinner from "../Spinner";
import LuzCaptura from "./LuzCaptura";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../layout/Navbar";
import { Button, Checkbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/16/solid";

const SubidorMuestra = () => {
  const { projectId } = useParams();

  const navigate = useNavigate();
  const username = localStorage.getItem("username");

  console.log("projectId:", projectId);

  const [imageData, setImageData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [puedoTomarMuestra, setPuedoTomarMuestra] = useState(false);
  const [mostrarMedidorLuz, setMostrarMedidorLuz] = useState(true);

  const [capturarUbicacion, setCapturarUbicacion] = useState(
    localStorage.getItem("capturarUbicacion")
  );

  const [resultadoObtenido, setResultadoObtenido] = useState(null);
  console.log("resultadoObtenido:", resultadoObtenido);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const handleCapturarUbicacion = () => {
    setCapturarUbicacion(!capturarUbicacion);
    localStorage.setItem("capturarUbicacion", !capturarUbicacion);
    if (!capturarUbicacion == true) {
      buscarCordenadas();
    }
  };

  const buscarCordenadas = async () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  };

  const setearImagenCapturada = (imagenCapturadaInput) => {
    setImageData(imagenCapturadaInput);
    setMostrarMedidorLuz(false);
  };

  const realizarAnalisisManualAdjunto = async (e) => {
    const files = e.target.files;
    console.log("Archivos seleccionados:", files);
    if (files && files.length > 0) {
      enviarImagenParaAnalisis(username, projectId, files[0]);
    }
  };

  const realizarAnalisis = async () => {
    try {
      const imageDataBlob = await fetch(imageData).then((res) => res.blob());
      enviarImagenParaAnalisis(username, projectId, imageDataBlob);
    } catch (error) {
      console.error("Error al realizar el análisis:", error);
    }
  };

  //////////////////////////

  const enviarImagenParaAnalisis = async (
    usuarioID,
    proyectoID,
    imagenBinaria
  ) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("usuario", usuarioID);
      formData.append("latitud", latitude);
      formData.append("longitud", longitude);
      formData.append("muestra", imagenBinaria);
      if (!proyectoID) {
        alert("No se encontró el ID del proyecto.");
        return;
      }
      let url_subir_muestra = `https://kong.viewmineral.cl/persistencia/proyecto/${proyectoID}/muestra-subir`;
      console.log("URL:", url_subir_muestra);
      const response = await axios.put(url_subir_muestra, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status !== 200) {
        alert("Error al subir la muestra");
      }
      console.log("Response:", response.data.resultados);
      setResultadoObtenido(response.data);
    } catch (error) {
      alert("Error al subir la muestra");
    }
    setLoading(false);
    solicitarMiniatura(proyectoID);
  };

  //////////////////////////

  const solicitarMiniatura = async (proyectoID) => {
    let url_generadora =
      "https://kong.viewmineral.cl/medidor/crear-miniatura-proyecto/" +
      proyectoID;
    axios
      .get(url_generadora)
      .then((response) => {
        console.log("Miniatura generada:", response.data);
      })
      .catch((error) => {
        console.error("Error al generar la miniatura:", error);
      });
  };

  ////////////////////////

  // al cargar que se obtengan las coordenadas
  useEffect(() => {
    //buscarCordenadas();
  }, []);

  if (resultadoObtenido !== null) {
    console.log(
      "Redireccionando a /project/process/" +
        resultadoObtenido.proceso_analisis.id_proceso
    );
    navigate(
      `/project/process/${resultadoObtenido.proceso_analisis.id_proceso}`
    );
    return null;
  } else
    return (
      <div className="bg-gray-100">
        <Navbar />
        <div className="step2 flex flex-col justify-center items-center">
          {loading && <Spinner />}
          <h2>Subir muestra como {username}</h2>
          <p>
            Capturar Ubicación:
            <Button
              className="ml-2 bg-green-600 hover:bg-green-600 text-white text-xs font-medium py-2 px-4 rounded"
              onClick={handleCapturarUbicacion}
            >
              {capturarUbicacion == true ? <span>Sí</span> : <span>Activar</span>}
            </Button>
          </p>

          {mostrarMedidorLuz && (
            <LuzCaptura
              habilitarTomaMuestra={setPuedoTomarMuestra}
              setCapturaTomada={setearImagenCapturada}
            />
          )}
          {!mostrarMedidorLuz && (
            <div className="">
              <div className="flex flex-row justify-center items-center">
                <button
                  className="bg-blue-600 hover:bg-blue-600 text-white text-xs font-medium py-2 px-4 rounded flex flex-row justify-center"
                  onClick={() => {
                    setImageData(null);
                    setMostrarMedidorLuz(true);
                  }}
                >
                  Repetir captura&nbsp;
                  <FaArrowRight />
                </button>
                <button
                  onClick={realizarAnalisis}
                  className="bg-green-600 hover:bg-green-600 text-white text-xs font-medium py-2 px-4 rounded ml-2 flex flex-row justify-center"
                >
                  Analizar &nbsp;
                  <FaBraille />
                </button>
              </div>
              <div className="flex flex-row justify-center items-center">
                {imageData && <img alt="imagen_capturada" src={imageData} />}
              </div>
            </div>
          )}

          {!imageData && (
            <>
              <input
                type="file"
                id="galleryInput"
                accept="image/*"
                multiple
                onChange={realizarAnalisisManualAdjunto}
                style={{ display: "none" }}
              />

              <label
                htmlFor="galleryInput"
                className="bg-green-600 hover:bg-green-600 text-white text-sm font-medium py-2 mt-2 px-4 rounded mr-2 flex flex-row items-center cursor-pointer"
              >
                Desde Galería &nbsp;
                <FaUpload />
              </label>
            </>
          )}
        </div>
      </div>
    );
};

export default SubidorMuestra;
