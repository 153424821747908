import React, { useState, useEffect } from "react";
import Navbar from "../layout/Navbar";
import axios from "axios";
import Spinner from "../Spinner";
import { FaEye, FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {useParams} from "react-router-dom";


const ProjectsView = () => {

  const { username } = useParams();

  const navigate = useNavigate();
  if (!localStorage.getItem("jwt")) {
    navigate("/");
  }

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProjects = async (nombreUsuario) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://kong.viewmineral.cl/persistencia/proyecto/listar/` + nombreUsuario
        );
        setProjects(response.data);
      } catch (error) {
        console.error("Error al obtener la lista de proyectos:", error);
      }
      setLoading(false);
    };
    
    if (username) {
      fetchProjects(username);
    } else {
      fetchProjects(localStorage.getItem("username"));
    }
  }, [username]);

  return (
    <div className="bg-gray-100">
      <Navbar />
      <h2 className="text-2xl font-semibold mb-6 mt-5 text-center">
        Mis Proyectos
      </h2>
      <div className="flex justify-center items-center">
        <a
          href="/create-project"
          className="bg-teal-600 hover:bg-teal-700 text-white py-2 px-4 rounded mb-2 focus:outline-none focus:shadow-outline  bottom-4 text-center flex flex-row items-center justify-center"
        >
          Crear nuevo proyecto &nbsp; <FaPlusCircle />
        </a>
      </div>

      {loading && <Spinner />}

      <div className="flex flex-wrap flex flex-row justify-center">
        {projects.map((project, index) => (
          <div
            key={project._id}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 relative"
          >
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-center">
              <a href={"/project/" + project._id}>
                <img
                  src={project.miniatura}
                  alt={project.miniatura}
                  className="w-full h-32 object-cover  "
                />
              </a>
              <p className="px-3 text-sm text-gray-700  ">
                Creación: {project.date_insert}
              </p>

              <div className="p-4 items-center justify-center">
                <h2 className="text-xl font-semibold mb-2 text-teal-700">
                  <a
                    href={"/project/" + project._id}
                    className=" flex flex-row items-center justify-center"
                    
                  >
                    {project.nombre}&nbsp; <FaEye />{" "}
                  </a>
                </h2>
                {/* <p className="text-gray-600 mb-2">{project.descripcion}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsView;
