import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaRegTimesCircle,
  FaPlus,
  FaUserFriends,
  FaWindowClose,
  FaKey,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SidebarMenu = ({ isOpen, toggleMenu }) => {
  const navigate = useNavigate();
  const [startX, setStartX] = useState(null);
  const [user, setUser] = useState(null); // Estado para almacenar la información del usuario
  const sidebarRef = useRef(null);

  useEffect(() => {
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      setUser(JSON.parse(userInfo));
    }

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleMenu]);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const irACambiarContrasena = () => {
    navigate("/usuario/cambiar-contrasena/"+user.username);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;
    const currentX = e.touches[0].clientX;
    const diff = currentX - startX;
    if (diff < -50) {
      toggleMenu();
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("jwt");
    localStorage.removeItem("projectId");
    localStorage.removeItem("analysisResult");
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <div
      className={`sidebar-menu ${isOpen ? "open" : ""}`}
      ref={sidebarRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="sidebar-header">
        <button className="close-btn float-right" onClick={toggleMenu}>
          <FaRegTimesCircle className="color-gray text-5x1" size={36} />
        </button>
        {user && (
          <p className="sidebar-user-info font-semibold text-xl">
            Bienvenido, {user.username}
          </p>
        )}
      </div>
      <ul className="menu-items">
        <li className="py-2">
          <Link to="/create-project" className="flex items-center">
            <FaPlus className="mr-2" />
            Crear Nuevo Proyecto
          </Link>
        </li>
        <li className="py-2">
         
          <Link to="/projects" className="flex items-center">
            <FaPlus className="mr-2" />
            Mis Proyectos
          </Link>
        </li>
        <li className="py-2">
          <button className="flex items-center">
            Versión {process.env.REACT_APP_VERSION || "Localhost"}
          </button>
        </li>
        <li className="py-2">
          <button onClick={handleLogout} className="flex items-center">
            <FaWindowClose className="mr-2" />
            Cerrar Sesión
          </button>
        </li>
        <li className="py-2">
          <button className="flex items-center" onClick={irACambiarContrasena}>
            <FaKey className="mr-2" />
            Cambiar Contraseña
          </button>
        </li>
      </ul>
      {/* insertar separador */}
      <hr className="menu-separator" />
      &nbsp;
      <hr className="menu-separator" />
      {user && user.role === "ADMINISTRADOR" && (
        <ul className="menu-items">
          <li className="py-2">
            <Link to="/usuarios/mantenedor" className="flex items-center">
              <FaUserFriends className="mr-2" />
              Mantenedor de Usuarios
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SidebarMenu;
