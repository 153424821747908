// Importa las dependencias necesarias
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaPlus,
  FaMinus,
  FaHandPointer,
} from "react-icons/fa";

function ResultadoAnalisis({ resultado }) {

  console.log("resultado mostrando:", resultado);

  const [manualNavigation, setManualNavigation] = useState(true);
  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false, // Deshabilitar las flechas del carrusel cuando está activa la navegación manual
    swipe: manualNavigation, // Deshabilitar el deslizamiento del carrusel cuando está activa la navegación manual
  };
  const handleToggleManualNavigation = () => {
    setManualNavigation((prevState) => !prevState); // Alternar entre activado y desactivado
  };

  // Funciones para manejar el zoom
  const MIN_ZOOM_LEVEL = 0.1;
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1); // Aumenta el nivel de zoom en 0.1
  };

  const [zoomLevel, setZoomLevel] = useState(1);
  const handleZoomOut = () => {
    if (zoomLevel > MIN_ZOOM_LEVEL) {
      setZoomLevel((prevZoomLevel) =>
        Math.max(prevZoomLevel - 0.1, MIN_ZOOM_LEVEL)
      ); // Disminuye el zoom solo si es mayor que MIN_ZOOM_LEVEL y no permite valores menores que MIN_ZOOM_LEVEL
    }
  };
  const mapSliderValueToZoomLevel = (sliderValue) => {
    // Asumiendo que sliderValue varía de 0 a 1
    // Puedes ajustar estos valores para cambiar el comportamiento del zoom
    const minZoom = 1;
    const maxZoom = 2;
    // Usa una función exponencial o cuadrática para un aumento no lineal
    // Aquí usamos una función cuadrática como ejemplo
    return minZoom + (maxZoom - minZoom) * sliderValue ** 2;
  };
  const handleZoomChange = (event) => {
    const sliderValue = parseFloat(event.target.value); // Valor del slider de 0 a 1
    const newZoomLevel = mapSliderValueToZoomLevel(sliderValue);
    setZoomLevel(newZoomLevel); // Actualiza el nivel de zoom con el valor calculado
  };

  return (
    <div>

      <Slider {...settings}>
        <div>
          <img
            src={resultado.analisis.link_mascara_analizada}
            alt="Imagen mixta"
            style={{
              transform: `scale(${zoomLevel})`,
              cursor: manualNavigation ? "grab" : "default",
            }}
          />
        </div>
        <div>
          <img
            src={resultado.analisis.link_imagen_entrada}
            alt="Imagen de entrada"
            style={{
              transform: `scale(${zoomLevel})`,
              cursor: manualNavigation ? "grab" : "default",
            }}
          />
        </div>
      </Slider>
      {/* Controles de zoom */}

      <div className="relative">
        <div className="range-zoom absolute">
          <div className="flex flex-row justify-around mt-3">
            
            <button
              onClick={handleToggleManualNavigation}
              className={`bg-gray-600 hover:bg-gray-500 text-white text-xs font-medium py-2 px-4 rounded mr-2 flex flex-row items-center ${
                manualNavigation ? "bg-blue-500 hover:bg-blue-400" : "" // Cambiar el color de fondo si la navegación manual está activa
              }`}
            >
              <FaHandPointer />
            </button>

            <button
              onClick={handleZoomOut}
              className="bg-gray-600 hover:bg-gray-500 text-white text-xs font-medium py-2 px-4 rounded mr-2 flex flex-row items-center"
            >
              <FaMinus />
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={(zoomLevel - 1) / 1}
              onChange={handleZoomChange}
              className="slider"
            />
            <button
              onClick={handleZoomIn}
              className="bg-gray-600 hover:bg-gray-500 text-white text-xs font-medium py-2 px-4 rounded mr-2 flex flex-row items-center"
            >
              <FaPlus />
            </button>
          </div>
        </div>
      </div>

      {/* Mostrar detecciones */}
      <div className="mt-10 flex flex-col p-5">
        <table className="text-center">
          <caption className="caption-bottom">
            Detecciones encontradas en el análisis
          </caption>
          <thead>
            <tr>
              <th>Mineral</th>
              <th>Ley de cobre</th>
            </tr>
          </thead>
          <tbody>
            {resultado.analisis.detecciones &&
              resultado.analisis.detecciones.map((deteccion, index) => (
                <tr key={index}>
                  <td>{deteccion.clase}</td>
                  <td>{deteccion.ley}%</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
    
  );
}

export default ResultadoAnalisis;
