import { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import { FaRetweet } from "react-icons/fa";
import { useEffect } from "react";

const videoConstraintsTrasera = {
  width: 1056,
  height: 704,
  facingMode: { exact: "environment" },
  frameRate: 60,
  autoFocus: true,
  zoom: 0.8,
};

const videoConstraintsFrontal = {
  width: 1056,
  height: 704,
  facingMode: "user",
};

const ImagenCapturada = ({ data }) => (
  <img alt="imagen_capturada" src={`${data}`} />
);

function LuzCaptura({ habilitarTomaMuestra, setCapturaTomada }) {
  const [captura, setCaptura] = useState(null);

  const [camara, setCamara] = useState("TRASERA");
  const [modo, setModo] = useState(videoConstraintsTrasera);
  const [lumen, setLumen] = useState(0); // Estado para almacenar el valor de brillo lumen
  const [mensaje, setMensaje] = useState(""); // Estado para almacenar el mensaje
  const [calidadLuz, setCalidadLuz] = useState("NO_OK");
  const webcamRef = useRef(null);
  const [medirLuz, setMedirLuz] = useState(true);
  const [distanciaResultado, setDistanciaResultado] = useState(null);

  const capture = useCallback(() => {
    if (medirLuz === false) return;

    const imageSrc = webcamRef.current?.getScreenshot();

    if (!imageSrc) return;
    // generar un blob a partir de la imagen capturada
    const byteCharacters = atob(imageSrc.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    analizarLumenes(blob);
    medirDistancia(blob);
  }, [webcamRef]);

  const capturarParaAnalisis = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptura(imageSrc);
    setCapturaTomada(imageSrc);
    setMedirLuz(false);
  }, [webcamRef]);

  const medirDistancia = (blob) => {
    try {
      const formData = new FormData();
      formData.append("frame", blob, "frame.jpg");

      fetch("https://kong.viewmineral.cl/distancia/upload_frame", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setDistanciaResultado(data);
        })
        .catch(console.error);
    } catch (error) {
      console.error("Error al medir la distancia", error);
    }
  };

  const analizarLumenes = (blob) => {
    try {
      const formData = new FormData();
      formData.append("frame", blob, "frame.jpg");
      fetch("https://kong.viewmineral.cl/medidor/upload_frame", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setLumen(data.brillo_lumen); // Actualizar el estado con el valor de brillo lumen
          setMensaje(data.mensaje); // Actualizar el estado con el mensaje
          setCalidadLuz(data.estado);
          if (data.estado === "OK") {
            habilitarTomaMuestra(true);
          } else {
            habilitarTomaMuestra(false);
          }
        })
        .catch(console.error);
    } catch (error) {
      console.error(error);
    }
  };

  // capturar imagen cada 1 segundo
  useEffect(() => {
    const intervalId = setInterval(capture, 1000); // Captura un frame cada 1000 ms (1 segundo)
    return () => clearInterval(intervalId); // Limpieza del intervalo
  }, [capture]);

  return (
    <div className="flex flex-col justify-center items-center">
      <button
        className="rounded-full bg-teal-600 hover:bg-teal-900 text-white py-2 px-4 text-center"
        onClick={() => {
          if (camara === "TRASERA") {
            setCamara("FRONTAL");
            setModo(videoConstraintsFrontal);
          } else {
            setCamara("TRASERA");
            setModo(videoConstraintsTrasera);
          }
        }}
      >
        <FaRetweet />
      </button>

      <div className="w-full">
        {distanciaResultado && (
          <h2 className="bg-blue-200 text-blue text-center ">
            {distanciaResultado.mensaje} ({distanciaResultado.profundidad})
          </h2>
        )}
      </div>

      {calidadLuz === "NO_OK" && captura === null && (
        <button className="bg-red-800 text-white text-center w-full  ">
          La cantidad de luz es insuficiente
        </button>
      )}
      {calidadLuz === "OK" && captura === null && (
        <button className="bg-green-800 text-white text-center w-full">
          Luz adecuada
        </button>
      )}
      {!captura && (
        <Webcam
          audio={false}
          height={176}
          screenshotFormat="image/jpeg"
          width={264}
          ref={webcamRef}
          videoConstraints={modo}
        ></Webcam>
      )}

      {captura && <ImagenCapturada data={captura} />}

      {/* {calidadLuz === "OK" && ( */}

      {!captura && (
        <>
          <button
            className="block w-16 h-16 rounded-full bg-black hover:bg-black-600 text-white text-center flex justify-center items-center cursor-pointer"
            onClick={capturarParaAnalisis}
          >
            <FaCamera className="color-white text-5x1" size={36} />
          </button>
        </>
      )}
      {/* {captura && (
        <button
          className="bg-blue-600 text-white py-2 px-4 text-center "
          onClick={() => {
            setMedirLuz(true);
            setCaptura(null);
          }}
        >
          Tomar Otra
        </button>
      )} */}
      {/* )} */}
    </div>
  );
}

export default LuzCaptura;
