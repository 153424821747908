import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbar";
import Spinner from "../Spinner";
import ListaDetecciones from "../muestra/ListaDetecciones";
import MascarasPorCapa from "../muestra/MascarasPorCapa";

function VerDetalleMuestra() {
  const { muestraID } = useParams();
  const navigate = useNavigate();
  const url_muestras =
    "https://kong.viewmineral.cl/persistencia/proyecto/get/" +
    muestraID +
    "/muestra";

  const [muestra, setMuestra] = useState(null);
  const [proyectoID, setProyectoID] = useState(null);
  const [loading, setLoading] = useState(false);

  const [flagMinerales, setFlagMinerales] = useState([
    { mineral: "Calcopirita", flag: true },
    { mineral: "Pirita", flag: true },
    { mineral: "Atacamita", flag: true },
    { mineral: "Crisocola", flag: true },
    { mineral: "Chalcantita", flag: true },
    { mineral: "Malaquita", flag: true },
    { mineral: "Covelina", flag: true },
    { mineral: "Bornita", flag: true },
    { mineral: "Calcosina", flag: true },
    { mineral: "Cuprita", flag: true },
    { mineral: "Azurita", flag: true },
  ]);

  const toggleMineralFlag = (mineral) => {
    const newFlagMinerales = flagMinerales.map((flag) => {
      if (flag.mineral === mineral) {
        return { mineral: mineral, flag: !flag.flag };
      } else {
        return flag;
      }
    });
    setFlagMinerales(newFlagMinerales);
  };

  const getFlagMineral = (mineral) => {
    try {
      const flag = flagMinerales.find((flag) => flag.mineral === mineral);
      return flag.flag;
    } catch (error) {
      console.error("Error al obtener el flag del mineral:", error);

      return false;
    }
  };

  useEffect(() => {
    const fetchMuestra = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url_muestras);
        console.log(response.data.muestra.analisis.detecciones);
        setMuestra(response.data.muestra);
        setProyectoID(response.data.muestra.id_proyecto);
      } catch (error) {
        console.error("Error al obtener la muestra:", error);
      }
      setLoading(false);
    };

    fetchMuestra();
  }, [muestraID, url_muestras]);

  return (
    <div>
      {loading && <Spinner />}

      <Navbar />
      <div className="overflow-hidden flex flex-col justify-center">
        <h1 className="text-2xl font-bold text-center mt-4">
          Detalle de la muestra
        </h1>
        <span className="text-gray-800 text-center mt-4">
          {/* Fecha {muestra.date_insert} */}
        </span>
        <div className="flex flex-col p-5 h-[300px] md:h-[400px]">
          {muestra && (
            <MascarasPorCapa
              resultado={muestra}
              flagMinerales={flagMinerales}
              getFlagMineral={getFlagMineral}
            />
          )}
        </div>
        <div className="flex flex-col p-5 h-auto">
          {muestra && (
            <ListaDetecciones
              resultado={muestra}
              flagMinerales={flagMinerales}
              toggleMineralFlag={toggleMineralFlag}
            />
          )}
        </div>

        <button
          className="bg-teal-600 hover:bg-teal-700 text-white py-1 px-4 rounded mb-2 focus:outline-none focus:shadow-outline text-center"
          onClick={() => navigate(`/project/${proyectoID}`)}
        >
          Volver al proyecto
        </button>
      </div>
    </div>
  );
}

export default VerDetalleMuestra;
