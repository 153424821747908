import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Carousel from "react-slick";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import { useLocation } from "react-router-dom";

const Carousels = ({ showCarousel, handleSlideChange, isAuthenticated }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const shouldShowCarousel = !isAuthenticated && currentPath === "/";

  console.log("isAuthenticated:", isAuthenticated);
  console.log("currentPath:", currentPath);

  return (
          <Carousel
            dots={true}
            
            arrows={true}
            slidesToShow={1}
            infinite={true}
            speed={600}
            afterChange={handleSlideChange}
          >
            <Slide1 />
            <Slide2 />
            <Slide3 />
          </Carousel>
  );
};

export default Carousels;
