import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { BarChart, Bar, PieChart, Pie, XAxis, YAxis, Tooltip, Legend, LineChart, Line, Cell } from 'recharts';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import './dashboard.css';  // Asegúrate de crear este archivo CSS para agregar estilos personalizados

const Dashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const url = 'https://kong.viewmineral.cl/persistencia/bitacora/buscar';
        const payload = {
            // 'param1': 'value1',
            // 'param2': 'value2',
        };

        axios.post(url, payload)
            .then(response => {
                if (response.data.code === 200 && response.data.message === 'OK') {
                    setData(response.data.data);
                } else {
                    console.error('Error en la respuesta:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error en la solicitud:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = useMemo(() => [
        { Header: 'ID', accessor: '_id' },
        { Header: 'Usuario', accessor: 'usuario' },
        { Header: 'Actividad', accessor: 'actividad' },
        { Header: 'Detalle', accessor: 'detalle' },
        { Header: 'Fecha', accessor: 'fecha' },
        { Header: 'Entidad', accessor: 'entidad' },
        { Header: 'ID Entidad', accessor: 'id_entidad' },
    ], []);

    const userActivityData = useMemo(() => {
        return data.reduce((acc, curr) => {
            acc[curr.usuario] = (acc[curr.usuario] || 0) + 1;
            return acc;
        }, {});
    }, [data]);

    const activityTypeData = useMemo(() => {
        return data.reduce((acc, curr) => {
            acc[curr.actividad] = (acc[curr.actividad] || 0) + 1;
            return acc;
        }, {});
    }, [data]);

    const timelineData = useMemo(() => {
        return data.map(item => ({
            usuario: item.usuario,
            actividad: item.actividad,
            fecha: new Date(item.fecha).toLocaleString(),
        }));
    }, [data]);

    const barChartData = useMemo(() => {
        return Object.keys(userActivityData).map(key => ({
            name: key,
            value: userActivityData[key],
        }));
    }, [userActivityData]);

    const pieChartData = useMemo(() => {
        return Object.keys(activityTypeData).map(key => ({
            name: key,
            value: activityTypeData[key],
        }));
    }, [activityTypeData]);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#EA3943', '#82CA9D', '#8884D8'];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos de Actividades');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(dataBlob, 'Datos_de_Actividades.xlsx');
    };

    if (loading) {
        return <div>Cargando datos...</div>;
    }

    return (
        <div className="dashboard">
            <h1>Dashboard de Actividades</h1>
            <button onClick={exportToExcel} className="download-button">Descargar Excel</button>
            <table {...getTableProps()} className="dashboard-table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="dashboard-th">
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} className="dashboard-td">
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

        </div>
    );
};

export default Dashboard;
