import { useEffect, useState } from "react";
import { useNavigate, useParams, redirect } from "react-router-dom";
import axios from "axios";
import ResultadoAnalisis from "./ResultadoAnalisis";
import Navbar from "../layout/Navbar";
import VerDetalleMuestra from "./VerDetalleMuestra";
import VerDetalleMuestraSinID from "./VerDetalleMuestraSinID";
import MascarasPorCapa from "../muestra/MascarasPorCapa";
import ListaDetecciones from "../muestra/ListaDetecciones";

function VerResultadoProceso() {
  const { processId } = useParams();
  const navigate = useNavigate();
  const [flagMinerales, setFlagMinerales] = useState([
    { mineral: "Calcopirita", flag: true },
    { mineral: "Pirita", flag: true },
    { mineral: "Atacamita", flag: true },
    { mineral: "Crisocola", flag: true },
    { mineral: "Chalcantita", flag: true },
    { mineral: "Malaquita", flag: true },
    { mineral: "Covelina", flag: true },
    { mineral: "Bornita", flag: true },
    { mineral: "Calcosina", flag: true },
    { mineral: "Cuprita", flag: true },
    { mineral: "Azurita", flag: true },
  ]);
  const getFlagMineral = (mineral) => {
    const flag = flagMinerales.find((flag) => flag.mineral === mineral);
    return flag.flag;
  }

  const toggleMineralFlag = (mineral) => {
    const newFlagMinerales = flagMinerales.map((flag) => {
      if (flag.mineral === mineral) {
        return { mineral: mineral, flag: !flag.flag };
      } else {
        return flag;
      }
    });
    setFlagMinerales(newFlagMinerales);
  }
  const url_muestras =
    "https://kong.viewmineral.cl/persistencia/proyecto/proceso/" +
    processId +
    "/muestras";
  const [muestras, setMuestras] = useState([]);
  useEffect(() => {
    const fetchMuestras = async () => {
      try {
        const response = await axios.get(url_muestras);
        console.log(response.data);
        setMuestras(response.data.muestras);
      } catch (error) {
        console.error("Error al obtener la lista de muestras:", error);
      }
    };

    fetchMuestras();
  }, [processId, url_muestras]);

  return (
    <div className="overflow-hidden flex flex-col justify-center">
      <Navbar />
      {muestras.map((muestra, index) => (
        <>
          <div className="flex flex-col p-5 h-[300px] md:h-[400px]">
          {muestra && <MascarasPorCapa resultado={muestra} flagMinerales={flagMinerales} getFlagMineral={getFlagMineral} />}
        </div>
        <div className="flex flex-col p-5 h-auto">
          {muestra && <ListaDetecciones resultado={muestra} flagMinerales={flagMinerales} toggleMineralFlag={toggleMineralFlag} />}
        </div>
          <a
            className="bg-teal-600 hover:bg-teal-700 text-white py-1 px-4 rounded mb-2 focus:outline-none focus:shadow-outline text-center"
            href={`/project/${muestra.id_proyecto}`}
          >
            Ir al proyecto
          </a>
          <button
          className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 text-center rounded"
          onClick={() => navigate(`/project/add-sample/${muestra.id_proyecto}`)}
        >
          Añadir otra muestra
        </button>
        </>
      ))}
    </div>
  );
}

export default VerResultadoProceso;
