import React, { useState, useEffect } from "react";
import Navbar from "../layout/Navbar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import Spinner from "../Spinner";
import { FaBackspace, FaEdit, FaMinus, FaPlus, FaStepBackward } from "react-icons/fa";
import ConfirmarEliminarProyecto from "./ConfirmarEliminarProyecto";

// Función para renderizar el gráfico de círculo
const renderPercentageCircle = (percentage) => {
  const radius = 60;
  const strokeWidth = 10;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      {/* Borde completo */}
      <circle
        stroke="#e0e0e0" // Color del borde completo
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Borde del porcentaje */}
      <circle
        stroke="#00ac74" // Color del borde que representa el porcentaje
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy="0.3em"
        fontSize="16"
        className="font-bold text-2xl"
      >
        {percentage.toFixed(1)}% {/* Redondea el porcentaje a 1 decimal */}
      </text>
    </svg>
  );
};

const VerProyecto = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [mostrarDialogoEliminar, setMostrarDialogoEliminar] = useState(false);

  const solicitarEliminarProyecto = () => {
    setMostrarDialogoEliminar(true);
    console.log("Solicitando eliminar proyecto");
  };

  useEffect(() => {
    const fetchVerProyectos = async () => {
      try {
        const response = await axios.get(
          `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/listar-muestras`
        );
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching project details:", error);
        setLoading(false);
      }
    };

    fetchVerProyectos();
  }, [projectId]);



  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (!project) {
    return <div>No se encontraron detalles para este proyecto.</div>;
  }

  return (
    <div className="bg-gray-100">
      {mostrarDialogoEliminar && <ConfirmarEliminarProyecto projectId={projectId} setMostrarDialogoEliminar={setMostrarDialogoEliminar} /> }
      
      <Navbar />
      <div className="text-center max-w-4xl mx-auto px-4 py-8">
        <div className="flex flex-row justify-between content-center">
          <button
            onClick={
              () => navigate("/projects")
            }
            className="font-semibold text-teal-600 hover:text-teal-500 flex flex-row items-center"
          >
            <FaStepBackward /> ver todos los proyectos
          </button>
        </div>
        <div className="flex flex-col justify-center mt-10">
          <h2 className="text-2xl font-semibold mb-2">{project.nombre}</h2>
          <p className="text-gray-600">Creación: {project.date_insert}</p>
          <p className="text-gray-600 mb-2 px-4">{project.descripcion}</p>
        </div>
        <div className="flex flex-col justify-center">
          {/* Agregar el gráfico de círculo debajo del título "Muestras:" */}
          <div className="flex flex-col justify-center items-center mt-4 mb-10">
            {renderPercentageCircle(project.ley_cobre_promedio)}
            <p className="text-center  py-0">Ley de cobre promedio</p>
          </div>
        </div>

        {/* boton de añadir nueva muestra */}
        <div className="bg-gray-50 px-4 py-3 flex flex-row justify-center bg-white shadow-lg rounded-lg overflow-hidden">
        <button
          className="rounded-md  px-3 py-2 text-sm font-semibold text-white hover:bg-green-900 bg-green-600 flex flex-row items-center justify-center"
          onClick={() => navigate(`/project/add-sample/${projectId}`)}
        >
          Análisis &nbsp;<FaPlus />
        </button>

        <button
          className="rounded-md  px-3 py-2 text-sm font-semibold text-white hover:bg-teal-900 bg-teal-600 flex flex-row items-center justify-center"
          onClick={() => navigate(`/project/edit/${projectId}`)}
        >
          Editar &nbsp;<FaEdit />
        </button>
        <button
          className="rounded-md  px-3 py-2 text-sm font-semibold text-white hover:bg-red-900 bg-red-600 flex flex-row items-center justify-center"
          onClick={() => solicitarEliminarProyecto()}
        >
          Eliminar &nbsp;<FaMinus />
        </button>
        </div>

        <h3 className="text-xl font-semibold mt-8 mb-4">
          Muestras Analizadas ({project?.muestras?.length})
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {project.muestras.map((muestra) => (
            <div
              key={muestra._id}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <img
                src={muestra.analisis.link_imagen_entrada}
                alt={muestra.analisis.link_imagen_entrada}
                className="w-full h-40 object-cover object-center"
              />
              <a href={`/muestra/detalle/${muestra._id}`} 
              className="bg-teal-600 hover:bg-teal-700 text-white py-1 px-4 rounded-full focus:outline-none focus:shadow-outline text-center shadow-lg overflow-hidden">
                  Ver Detalle
              </a>
              <div className="p-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="bg-white divide-y divide-gray-200">
                    {muestra.analisis.detecciones.map((deteccion, index) => (
                      <tr key={index}>
                        <td className="px-6 py-2  text-sm font-medium text-gray-900">
                          {deteccion.clase}
                        </td>
                        <td className="px-6 py-2  text-sm text-gray-500">
                          Ley de cobre {deteccion.ley}%
                        </td>
                      </tr>
                    ))}
                    {muestra.latitud && muestra.longitud && (
                      <tr>
                        <td
                          className="px-6 py-2  text-sm text-gray-500"
                          colSpan={2}
                        >
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              muestra.latitud +
                              "," +
                              muestra.longitud
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500"
                          >
                            {" "}
                            Ver en Google Maps
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerProyecto;
