import { Switch } from "@headlessui/react";
import { useState } from "react";
import NavbarPublica from "../components/layout/NavbarPublica";
import axios from "axios";
import usePaises from "../hooks/usePaises";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FormularioRegistro() {
  const { paises, loading, buscarPaisPorCodigo } = usePaises();
  const [cargando, setCargando] = useState(false);

  const [agreed, setAgreed] = useState(false);
  const [formulario, setFormulario] = useState({
    nombre: "",
    apellido: "",
    empresa: "",
    email: "",
    telefono: "",
    mensaje: "",
    pais: "",
  });
  const [resultado, setResultado] = useState(null);
  const [dialPais, setDialPais] = useState("+56");

  const handleAgreed = () => setAgreed(!agreed);

  const handleCambioPais = (event) => {
    const { name, value } = event.target;
    console.log("handleCambioPais:", { name, value });
    const paisSeleccionado = buscarPaisPorCodigo(value);
    setDialPais(paisSeleccionado.dial_code);
    console.log("Pais seleccionado:", paisSeleccionado);
    setFormulario({ ...formulario, [name]: paisSeleccionado.name_es });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const validarFormulario = () => {
    if (
      formulario.nombre === "" ||
      formulario.apellido === "" ||
      formulario.empresa === "" ||
      formulario.email === "" ||
      formulario.telefono === "" ||
      formulario.mensaje === ""
    ) {
      alert("Por favor, complete todos los campos");
      return false;
    }
    return true;
  };

  const crearProyectoDummy = async (usuario_id) => {
    let nuevo_proyecto_url_get =
      "https://kong.viewmineral.cl/persistencia/proyecto/crear_proyectos_demo/" +
      usuario_id;

    axios
      .get(nuevo_proyecto_url_get)
      .then((response) => {
        console.log(response.data.mensaje);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validarFormulario()) {
      return;
    }
    
    setAgreed(false);
    let nuevo_usuario_url =
    "https://kong.viewmineral.cl/persistencia/usuarios/crear-usuario-cliente";
    // let nuevo_usuario_url = "http://localhost:8000/usuarios/crear-usuario-cliente";
    
    console.log("Nuevo usuario:", formulario);
    
    // Enviar formulario a la API
    
    setCargando(true);
    let payload = {
      nombre: formulario.nombre,
      apellido: formulario.apellido,
      empresa: formulario.empresa,
      email: formulario.email,
      telefono: dialPais + " " + formulario.telefono,
      pais: formulario.pais,
      mensaje: formulario.mensaje,
    };

    axios
      .post(nuevo_usuario_url, payload)
      .then((response) => {
        console.log(response.data.mensaje);
        setResultado(response.data);
        alert(response.data.mensaje);

        crearProyectoDummy(formulario.email);
      })
      .catch((error) => {
        console.error(error);
      });

    console.log("Formulario enviado");
    setCargando(false);
  };

  return (
    <div className="lg:max-w-lg">
      {cargando && (
        <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md border border-gray-200">
            <p className="text-gray-800">Enviando formulario...</p>
          </div>
        </div>
      )}

      {resultado && (
        <div
          className={classNames(
            resultado.error ? "bg-red-100" : "bg-green-100",
            "p-4 rounded-md border border-gray-200"
          )}
        >
          {resultado.error ? (
            <p className="text-red-800">{resultado.mensaje}</p>
          ) : (
            <p className="text-green-800">{resultado.mensaje}</p>
          )}
        </div>
      )}

      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Registro de Nuevo Usuario
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Registrese para acceder a un demo de la aplicación de View Mineral,
          por tiempo limitado.
        </p>
      </div>
      <form className="mx-auto mt-16 max-w-xl sm:mt-20" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Nombre
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nombre"
                onChange={handleChange}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Apellido
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="apellido"
                onChange={handleChange}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="company"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Nombre de Empresa
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="empresa"
                onChange={handleChange}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="pais"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              País
            </label>
            <div className="mt-2.5">
              <select
                name="pais"
                onChange={handleCambioPais}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Seleccione un país</option>
                {loading ? (
                  <option>Cargando...</option>
                ) : (
                  paises.map((pais) => (
                    <option key={pais._id} value={pais.code}>
                      {pais.name_es}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="phone-number"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Teléfono de Contacto
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">{dialPais}</span>
              </div>
              <input
                type="text"
                name="telefono"
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 pl-20 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="987654321"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Mensaje
            </label>
            <div className="mt-2.5">
              <textarea
                name="mensaje"
                onChange={handleChange}
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="flex-1">
            <a
              href="/poliza/privacidad"
              target="_blank"
              className="font-semibold text-indigo-600"
            >
              Ver Política de Privacidad
            </a>
          </div>
          <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? "bg-indigo-600" : "bg-gray-200",
                  "flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? "translate-x-3.5" : "translate-x-0",
                    "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>

            <Switch.Label className="text-sm leading-6 text-gray-600">
              Estoy de acuerdo con la{" "}
              <a
                href="/poliza/privacidad"
                target="_blank"
                className="font-semibold text-indigo-600"
              >
                politica de privacidad
              </a>{" "}
              de datos de View Mineral.{" "}
              <a
                href="/poliza/privacidad"
                target="_blank"
                className="font-semibold text-indigo-600"
              >
                Estoy de acuerdo
              </a>
              .
            </Switch.Label>
          </Switch.Group>
        </div>
        {agreed && (
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Registro
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default function RegistroNuevoUsuario() {
  return (
    <>
      <NavbarPublica />
      <div className="overflow-hidden bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 py-10">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <FormularioRegistro />
            <img
              src="img/captura.png"
              alt="View Mineral screenshot"
              className="max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
      </div>
    </>
  );
}
