import { useState } from 'react';
import axios from 'axios';

const urlChangePassword = 'https://kong.viewmineral.cl/persistencia/usuarios/change-password';

/* Response example:
{
    "codigo": 0,
    "mensaje": "Password actualizado"
}
*/

const useUserChangePassword = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const changePassword = async (username, oldPassword, newPassword) => {
        setLoading(true);
        setError(null);

        try {

            const payload = {
                "username": username,
                "password": oldPassword,
                "new_password": newPassword
                }

            // Make the API call to change the password
            const response = await axios.post(urlChangePassword, payload);

            console.log(response.data);

            // Handle the response
            if (response.data.codigo !== 0) {
                throw new Error(response.data.mensaje);
            }

        } catch (error) {
            setError(error.message);
        }

        setLoading(false);
    };

    return { loading, error, changePassword };
};

export default useUserChangePassword;