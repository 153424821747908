import { Fragment, useRef, useState } from "react";
import {
  Dialog,
  Transition,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { FaTrash, FaWindowClose } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";

export default function ConfirmarEliminarProyecto({
  projectId,
  setMostrarDialogoEliminar,
}) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();

  const urlEliminarProyecto = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/eliminar`;

  const eliminarProyecto = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(urlEliminarProyecto);
      console.log(response.data);
      cerrarDialogo();
      navigate("/projects");
    } catch (error) {
      console.error("Error al eliminar el proyecto:", error);
    }
    setLoading(false);
  };

  const cerrarDialogo = () => {
    setOpen(false);
    setMostrarDialogoEliminar(false);
  };

  return (
    <Transition show={open} as={Fragment}>
      
      {loading && <Spinner />}

      <Dialog
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={cerrarDialogo}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Eliminar Proyecto
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-400">ID:{projectId}</p>
                        <p className="text-sm text-gray-700">
                          Esta seguro que desea eliminar el registro? Todos los
                          datos serán eliminados de forma permanente. Esta
                          accion no se puede deshacer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row justify-center sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-700 hover:text-white hover:bg-gray-600 flex flex-row items-center justify-center"
                    onClick={() => cerrarDialogo()}
                    ref={cancelButtonRef}
                  >
                    Cancelar &nbsp; <FaWindowClose />
                  </button>
                  <button
                    type="button"
                    className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white hover:bg-red-900 flex flex-row items-center justify-center"
                    onClick={() => eliminarProyecto()}
                  >
                    Eliminar &nbsp; <FaTrash />
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
