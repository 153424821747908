import React from "react";
import Navbar from "../../components/layout/Navbar";

function PoliticaDePrivacidad() {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Política de Privacidad</h1>
        
        <h2 className="text-2xl font-bold mb-4">1. Introducción</h2>
        <p className="mb-4">
          View Mineral Ltda. ("View Mineral", "nosotros", "nos" o "nuestro") se compromete a proteger la privacidad de sus usuarios ("usted" o "su"). Esta Política de privacidad ("Política") describe cómo recopilamos, usamos y divulgamos su información personal cuando utiliza nuestro sitio web y nuestra aplicación móvil (en conjunto los "Servicios"). Al utilizar los Servicios, usted acepta estar sujeto a esta Política.
        </p>
        
        <h2 className="text-2xl font-bold mb-4">2. Recopilación de información personal</h2>
        <p className="mb-4">Recopilamos los siguientes tipos de información personal:</p>
        <p className="mb-4">
          <b>Información que usted nos proporciona:</b> Recopilamos información personal que usted nos proporciona directamente, como su nombre, dirección de correo electrónico y número de teléfono. Esto puede incluir información que usted proporciona cuando crea una cuenta, utiliza nuestros Servicios o se pone en contacto con nosotros para obtener asistencia.
        </p>
        <p className="mb-4">
          <b>Información recopilada automáticamente:</b> Cuando utiliza nuestros Servicios, recopilamos automáticamente cierta información sobre su dispositivo y su actividad. Esta información puede incluir su dirección IP, tipo de navegador, sistema operativo, referencias y páginas visitadas. También podemos recopilar información sobre su ubicación si usted habilita los servicios de ubicación en su dispositivo.
        </p>
        <p className="mb-4">
          <b>Información de terceros:</b> Podemos recopilar información sobre usted de terceros, como redes sociales y plataformas de análisis.
        </p>
        
        <h2 className="text-2xl font-bold mb-4">3. Uso de la información personal</h2>
        <p className="mb-4">Utilizamos su información personal para los siguientes propósitos:</p>
        <ul className="list-disc pl-8 mb-4">
          <li>Para proporcionarle los Servicios: Utilizamos su información personal para proporcionarle los Servicios que usted solicita.</li>
          <li>Para mejorar nuestros Servicios: Utilizamos su información personal para mejorar nuestros Servicios y desarrollar nuevos productos y características.</li>
          <li>Para comunicarnos con usted: Utilizamos su información personal para comunicarnos con usted sobre su cuenta, los Servicios y otras ofertas relevantes.</li>
          <li>Para fines de marketing: Podemos utilizar su información personal para enviarle correos electrónicos de marketing y otras comunicaciones promocionales. Puede optar por no recibir estos correos electrónicos haciendo clic en el enlace para cancelar la suscripción que aparece en la parte inferior de cada correo electrónico.</li>
          <li>Para fines legales: Podemos utilizar su información personal para fines legales, como para cumplir con citaciones judiciales o solicitudes gubernamentales.</li>
        </ul>
        
        <h2 className="text-2xl font-bold mb-4">4. Divulgación de información personal</h2>
        <p className="mb-4">Podemos divulgar su información personal a terceros en las siguientes circunstancias:</p>
        <ul className="list-disc pl-8 mb-4">
          <li>A proveedores de servicios: Podemos compartir su información personal con proveedores de servicios que nos ayudan a operar nuestros Servicios, como proveedores de alojamiento web y proveedores de servicios de pago.</li>
          <li>A terceros con su consentimiento: Podemos compartir su información personal con terceros cuando usted nos dé su consentimiento.</li>
          <li>Para fines legales: Podemos divulgar su información personal para fines legales, como para cumplir con citaciones judiciales o solicitudes gubernamentales.</li>
          <li>En caso de venta o fusión: Si View Mineral es adquirido o fusionado con otra empresa, su información personal puede ser transferida al nuevo propietario.</li>
        </ul>
        
        <h2 className="text-2xl font-bold mb-4">5. Seguridad de la información personal</h2>
        <p className="mb-4">
          Tomamos medidas de seguridad razonables para proteger su información personal contra pérdida, robo, uso indebido, acceso no autorizado, divulgación, alteración o destrucción. Sin embargo, ninguna medida de seguridad es perfecta y no podemos garantizar la seguridad absoluta de su información personal.
        </p>
        
        <h2 className="text-2xl font-bold mb-4">6. Sus opciones</h2>
        <p className="mb-4">
          Puede acceder y actualizar su información personal en cualquier momento iniciando sesión en su cuenta y visitando su página de perfil. También puede solicitar que eliminemos su información personal enviándonos un correo electrónico a <a href="mailto:info@viewmineral.cl" className="text-blue-500 underline">info@viewmineral.cl</a>.
        </p>
        
        <h2 className="text-2xl font-bold mb-4">7. Cambios en esta Política</h2>
        <p className="mb-4">
          Podemos actualizar esta Política periódicamente. Le notificaremos de cualquier cambio publicando la Política revisada en nuestro sitio web.
        </p>
        
        <h2 className="text-2xl font-bold mb-4">8. Contacto</h2>
        <p className="mb-4">
          Si tiene alguna pregunta sobre esta Política puede contactarnos en <a href="mailto:info@viewmineral.cl" className="text-blue-500 underline">info@viewmineral.cl</a>.
        </p>
        
        <h2 className="text-2xl font-bold mb-4">9. Fecha de vigencia</h2>
        <p className="mb-4">
          Esta Política entró en vigencia el 1 de junio de 2024.
        </p>
      </div>
    </div>
  );
}

export default PoliticaDePrivacidad;
