import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";

const HelpButton = () => {
  const [showModal, setShowModal] = useState(false);

  const helpOptions = [
    "Opción de ayuda 1",
    "Opción de ayuda 2",
    "Opción de ayuda 3",
    // Agrega más opciones de ayuda según sea necesario
  ];

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {/* Botón flotante */}
      <button
        onClick={toggleModal}
        className="fixed bottom-8 right-8 bg-blue-500 hover:bg-blue-600 text-white rounded-full p-3 shadow-md"
      >
        <FaQuestionCircle className="w-6 h-6" />
      </button>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-8 max-w-lg">
            <h2 className="text-lg font-semibold mb-4">Opciones de ayuda</h2>
            <div className="overflow-y-scroll h-80">
              <ol className="list-decimal font-medium">
                <li>
                  1. Consejos para tomar una fotografía de análisis.
                  <ol className="list-disc font-light pl-2">
                    <li>
                      1.1. Muy Oscura, muy luminosa: Es recomendable usar el kit
                      de Luz que entrega View Mineral, si no dispones de el,
                      debes considerar tomar la fotografía en condiciones no muy
                      oscura ni muy expuesta. (Vincular link de video de youtube
                      de uso que esta en proceso de creación).
                    </li>
                    <li>
                      1.2. Muy Lejana, Muy cercana: Es recomendable tomar una
                      fotografía a una distancia aproximada de 15 cm de la
                      muestra. (Vincular link de video de youtube de uso que
                      está en proceso de creación).
                    </li>
                    <li>
                      1.3. Muy borrosa: Considera que el enfoque que está
                      aplicando la cámara al momento de sacar la foto.
                    </li>
                  </ol>
                </li>
                <li>
                  2. Apoyo Geológico en línea:
                  <ol className="list-disc font-light pl-2">
                    <li>
                      2.1. Envió de proyecto al correo info@viewmineral.cl con
                      la consulta, indicando que si su plan incluye este
                      contenido responderemos en 24 horas.
                    </li>
                  </ol>
                </li>
                <li>3. Información sobre la aplicación.</li>
                <li>
                  <a
                    href="/poliza/privacidad"
                    className="text-emerald-700 no-underline hover:underline"
                    target="_blank"
                  >
                    4. Política de privacidad.
                  </a>
                </li>
                <li>
                  <a
                    href="/poliza/terminos"
                    className="text-emerald-700 no-underline hover:underline"
                    target="_blank"
                  >
                    5. Terminos de Uso.
                  </a>{" "}
                </li>
               
              </ol>
            </div>
            <button
              onClick={toggleModal}
              className="mt-6 bg-gray-600 hover:bg-gray-800 text-white rounded px-4 py-2"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpButton;
