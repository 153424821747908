import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import ProjectsView from "./components/project/ProjectsView";
import VerProyecto from "./components/project/VerProyecto";
import SubidorMuestra from "./components/project/SubidorMuestra";
import VerResultadoProceso from "./components/project/VerResultadoProceso";
import FormularioProyecto from "./components/project/FormularioProyecto";
import VerDetalleMuestra from "./components/project/VerDetalleMuestra";
import ConfirmarEliminarProyecto from "./components/project/ConfirmarEliminarProyecto";
import RegistroNuevoUsuario from "./pages/RegistroNuevoUsuario";
import LayoutPrivado from "./components/layout/LayoutPrivado";
import EditarUsuario from "./pages/usuarios/EditarUsuario";
import PlanesViewMineral from "./pages/ventas/PlanesViewMineral";
import FormPruebadeCampo from "./components/project/FormPruebadeCampo";
import FormPruebadeCampo2 from "./components/project/FormPruebadeCampo2";
import FormPruebadeCampoazu from "./components/project/FormPruebadeCampoazu";
import FormPruebadeCampo4 from "./components/project/FormPruebadeCampo4";
import FormPruebadeCampo5 from "./components/project/FormPruebadeCampo5";
import FormPruebadeCampo6 from "./components/project/FormPruebadeCampo6";
import PoliticaDePrivacidad from "./pages/politicas/PoliticaDePrivacidad";
import TerminosDeServicio from "./pages/politicas/terminosdeservicio";
import MantenedorUsuarios from "./pages/usuarios/MantenedorUsuarios";
import Dashboard from "./components/project/dashboard";
import ChangePassword from "./pages/ChangePassword";

const App = () => {
  // Imprimir variable REACT_APP_VERSION en consola
  console.log("Versión de la aplicación:", process.env.REACT_APP_VERSION);


  // Inicializar capturarUbicacion en localStorage
  if (!localStorage.getItem("capturarUbicacion")) {
    localStorage.setItem("capturarUbicacion", false);
  }


  return (
    <Router>
      <Routes>
        <Route path="/" element={<PlanesViewMineral />} />
        <Route path="/login" element={<Login />} />
        <Route path="/test" element={<ConfirmarEliminarProyecto />} />
        <Route path="/create-project" element={<FormularioProyecto />} />
        <Route path="/projects" element={<ProjectsView />} />
        <Route path="/projects/:username" element={<ProjectsView />} />
        <Route path="/project/:projectId" element={<VerProyecto />} />
        <Route
          path="/project/add-sample/:projectId"
          element={<SubidorMuestra />}
        />
        <Route
          path="/project/edit/:projectId"
          element={<FormularioProyecto />}
        />
        <Route
          path="/project/process/:processId"
          element={<VerResultadoProceso />}
        />
        <Route
          path="/muestra/detalle/:muestraID"
          element={<VerDetalleMuestra />}
        />
        <Route path="/health-check" element={<h1>health-check</h1>} />
        <Route
          path="/registro-nuevo-usuario"
          element={<RegistroNuevoUsuario />}
        />
        <Route path="/usuarios" element={<LayoutPrivado />}>
          <Route path="mantenedor" element={<MantenedorUsuarios />} />
          <Route path="detalle/:userId" element={<h1>Usuario</h1>} />
          <Route path="editar/:userId" element={<EditarUsuario />} />
        </Route>
        <Route path="*" element={<h1>404</h1>} />
        <Route
          path="/project/process/:processId/pruebadecampo"
          element={<FormPruebadeCampo />}
        />
        <Route
          path="/project/process/:processId/pruebadecampo2"
          element={<FormPruebadeCampo2 />}
        />
        <Route
          path="/project/process/:processId/pruebadecampoazu"
          element={<FormPruebadeCampoazu />}
        />
        <Route
          path="/project/process/:processId/pruebadecampo4"
          element={<FormPruebadeCampo4 />}
        />
        <Route
          path="/project/process/:processId/pruebadecampo5"
          element={<FormPruebadeCampo5 />}
        />
        <Route
          path="/project/process/:processId/pruebadecampo6"
          element={<FormPruebadeCampo6 />}
        />
        <Route
          path="project/dashboard"
          element={<Dashboard />}
        />
        <Route path="/poliza/terminos" element={<TerminosDeServicio />} />
        <Route path="/poliza/privacidad" element={<PoliticaDePrivacidad />} />
        <Route path="/usuario/cambiar-contrasena/:username" element={<ChangePassword />} />
      </Routes>
    </Router>
  );
};

export default App;
