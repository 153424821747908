import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { FaBackward, FaSave } from "react-icons/fa";

function EditarUsuario() {
  const { userId } = useParams();
  const [usuario, setUsuario] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [formulario, setFormulario] = useState({
    nombre: "",
    apellido: "",
    empresa: "",
    email: "",
    telefono: "",
    role: "",
    creditos: 0,
    expiracion: "2030-05-23",
  });

  const cadena_fecha_to_date = (cadena) => {
    // cadena de ejemplo 2030-05-23 12:11:58
    let fecha = cadena.split(" ")[0];
return fecha;
  };

  const [resultado, setResultado] = useState(null);

  useEffect(() => {
    const obtenerUsuario = async () => {
      setLoading(true);
      try {
        let headers = {
          Authorization: localStorage.getItem("jwt"),
        };

        const response = await axios.get(
          `https://kong.viewmineral.cl/persistencia/usuarios/${userId}/obtener-usuario`,
          { headers }
        );
        console.log("Usuario obtenido:", response.data.usuario);
        setFormulario(response.data.usuario);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener usuario:", error);
        setLoading(false);
      }
    };
    obtenerUsuario();
  }, [userId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let headers = {
      Authorization: localStorage.getItem("jwt"),
    };
    let api_editar_usuario =
      "https://kong.viewmineral.cl/persistencia/usuarios/" + userId + "/editar-usuario";

    

    // Enviar formulario a la API

    let request = {
      nombre: formulario.nombre,
      apellido: formulario.apellido,
      telefono: formulario.telefono,
      empresa: formulario.empresa,
      email: formulario.email,
      role: formulario.role,
      estado: formulario.estado,
      creditos: formulario.creditos,
      expiracion: formulario.expiracion +" 23:59:59",
    };

    console.log("editando usuario:", request);

    axios
      .put(api_editar_usuario, request, { headers })
      .then((response) => {
        console.log(response.data.mensaje);
        setResultado(response.data);
        alert(response.data.mensaje);
      })
      .catch((error) => {
        console.error(error);
      });

    console.log("Formulario enviado");
  };

  return (
    <div className="container mx-auto p-4">
      {loading && <Spinner />}
      <h1 className="text-2xl font-semibold text-gray-900">Editar Usuario</h1>

      <form className="mx-auto mt-4 max-w-xl sm:mt-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Nombre
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nombre"
                onChange={handleChange}
                value={formulario.nombre}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Apellido
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="apellido"
                value={formulario.apellido}
                onChange={handleChange}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="company"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Nombre de Empresa
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="empresa"
                value={formulario.empresa}
                onChange={handleChange}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                value={formulario.email}
                onChange={handleChange}
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="phone-number"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Teléfono de Contacto
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">+56 9</span>
              </div>
              <input
                type="text"
                name="telefono"
                value={formulario.telefono}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 pl-20 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="987654321"
              />
            </div>
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="creditos"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Créditos
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="number"
                name="creditos"
                value={formulario.creditos}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="99"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="expiracion" className="block text-sm font-semibold leading-6 text-gray-900">
              Fecha de Expiración
            </label>
            <div className="mt-2.5">
              <input 
                type="date"
                name="expiracion"
                value={ cadena_fecha_to_date(formulario.expiracion) }
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>


          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Tipo de Usuario
            </label>
            <div className="mt-2.5">
              <select
                name="role"
                value={formulario.role}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="CLIENTE">Cliente</option>
                <option value="ADMINISTRADOR">Administrador</option>
              </select>
            </div>
          </div>
        </div>
        
        <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="submit"
                >
                  Guardar Cambios &nbsp;
                  <FaSave />
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="button"
                  onClick={() => navigate("/usuarios/mantenedor")}
                >
                  Volver al Listado&nbsp;
                  <FaBackward />
                </button>
              </div>
      </form>
    </div>
  );
}

export default EditarUsuario;
