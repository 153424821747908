import React from 'react';
import './Spinner.css'; 

import logoIso from '../logo-iso.svg'; 

const Spinner = () => (
  <div className="spinner-overlay">
    <img src={logoIso} alt="Loading..." className="spinner-image" />
    {/* texto mientras se procesa */}
    <h2 className="spinner-text">Procesando...</h2>
  </div>
);

export default Spinner;
