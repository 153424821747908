import React, { useState } from "react";
import useUserChangePassword from "../hooks/useUserChangePassword";
import { useParams, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const { username } = useParams();
  const navigate = useNavigate();

  const { loading, error, changePassword } = useUserChangePassword();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorFormulario, setErrorFormulario] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
        setConfirmPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorFormulario(null);

    if (currentPassword.trim() === "" || newPassword.trim() === "" || confirmPassword.trim() === "") {
        setErrorFormulario("Todos los campos son obligatorios");
        return;
    }

    if (newPassword !== confirmPassword) {
        setErrorFormulario("Las nueva contraseña y la confirmación no coinciden");
        return;
    }

    // Invocar el custom hook aquí para realizar la lógica de cambio de contraseña
    changePassword(username, currentPassword, newPassword);

    if (error) {
        setErrorFormulario("Error al cambiar la contraseña");
    } else {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        alert("Contraseña cambiada correctamente");
        navigate("/login");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form
        className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        
        <h1 className="text-2xl text-center mb-4">Formulario de Cambio de Contraseña</h1>
        {loading && <p className="text-center my-4">Cargando...</p>}
        {error && <p className="text-center my-4 text-red-700">{error}</p>}
        {errorFormulario && <p className="text-center my-4 text-red-700">{errorFormulario}</p>}

        

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="currentPassword"
          >
            Contraseña actual
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="currentPassword"
            name="currentPassword"
            type="password"
            placeholder="Contraseña actual"
            value={currentPassword}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="newPassword"
          >
            Contraseña nueva
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="Contraseña nueva"
            value={newPassword}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="confirmPassword"
          >
            Confirmar contraseña
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirmar contraseña nueva"
            value={confirmPassword}
            onChange={handleChange}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Cambiar contraseña
          </button>
          <button className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" onClick={
                () => {
                    navigate(-1); 
                }
          }>
            Cancelar
            </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
