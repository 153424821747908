import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../layout/Navbar";
import Spinner from "../Spinner";
import { FaBackward, FaForward, FaRegSave, FaSave } from "react-icons/fa";

function FormularioProyecto() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const url_get_project = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/registro`;
  const url_put_project_update = `https://kong.viewmineral.cl/persistencia/proyecto/${projectId}/actualizar`;
  const url_post_project_create =
    "https://kong.viewmineral.cl/persistencia/proyecto/crear";
  const url_nav_to_project = "/project/" + projectId;
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: project ? project.nombre : "",
    descripcion: project ? project.descripcion : "",
    usuario: project ? project.usuario : "",
  });

  // Funcion actualizar proyecto
  const actualizarProyecto = async () => {
    setLoading(true);
    try {
      const request = {
        _id: projectId,
        nombre: form.nombre,
        descripcion: form.descripcion,
        usuario: localStorage.getItem("username"),
        date_insert: project.date_insert,
      };

      const response = await axios.put(url_put_project_update, request);
      console.log(response.data);
      setProject(response.data);
      navigate("/project/"+projectId);
    } catch (error) {
      console.error("Error al actualizar el proyecto:", error);
    }
    setLoading(false);
    solicitarMiniatura(projectId);
  };

  const crearProyecto = async () => {
    setLoading(true);
    try {
      const request = {
        nombre: form.nombre,
        descripcion: form.descripcion,
        usuario: localStorage.getItem("username"),
      };

      const response = await axios.post(url_post_project_create, request);
      console.log(response.data);
      setProject(response.data);
      navigate("/project/"+response.data._id);
    } catch (error) {
      console.error("Error al crear el proyecto:", error);
    }
    setLoading(false);

  }


  const solicitarMiniatura = async (proyectoID) => {
    let url_generadora =
      "https://kong.viewmineral.cl/medidor/crear-miniatura-proyecto/" +
      proyectoID;
    axios
      .get(url_generadora)
      .then((response) => {
        console.log("Miniatura generada:", response.data);
      })
      .catch((error) => {
        console.error("Error al generar la miniatura:", error);
      });
  };

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url_get_project);
        console.log(response.data);
        setProject(response.data);
        setForm({
          nombre: response.data.nombre,
          descripcion: response.data.descripcion,
          usuario: response.data.usuario,
        });
      } catch (error) {
        console.error("Error al obtener el proyecto:", error);
      }
      setLoading(false);
    };

    console.log("Usuario:", localStorage.getItem("username"));
    if (projectId) {
      fetchProject();
    } else {
      console.log("No se ha especificado un ID de proyecto.");
      console.log("Creando un nuevo proyecto...");
      setProject({
        nombre: "",
        descripcion: "",
        usuario: localStorage.getItem("username"),
        date_insert: null,
      });
      setForm({
        nombre: "",
        descripcion: "",
        usuario: localStorage.getItem("username"),
      });
    }
  }, [projectId, url_get_project]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", form);
    // Agrega aquí la lógica para enviar el formulario
    if (projectId) {
      console.log("Actualizando proyecto...");
      actualizarProyecto();
    } else {
      console.log("Creando proyecto...");
      crearProyecto();
    }
  };

  return (
    <div>
      <Navbar />

      {loading && <Spinner />}
      <div className="px-8 pt-6 pb-8 mb-4">
        {projectId && <h1 className="text-2xl font-bold">Edición Proyecto</h1>}
        {!projectId && <h1 className="text-2xl font-bold">Nuevo Proyecto</h1>}
        {project && (
          <form className="mt-3" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="nombre"
              >
                Nombre:
              </label>
              <input
                className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                type="text"
                name="nombre"
                required
                value={form.nombre}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="descripcion"
              >
                Descripción:
              </label>
              <input
                className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                type="text"
                name="descripcion"
                required
                value={form.descripcion}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="usuario"
              >
                Usuario:
              </label>
              <input
                className="mt-1 block w-full rounded-md bg-gray-100 border border-green-500 focus:ring focus:ring-green py-2 px-3"
                type="text"
                name="usuario"
                value={form.usuario}
                onChange={handleChange}
                disabled
              />
            </div>
            {projectId && (
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="submit"
                >
                  Actualizar&nbsp;
                  <FaSave />
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="button"
                  onClick={() => navigate(url_nav_to_project)}
                >
                  Volver &nbsp;
                  <FaForward />
                </button>
              </div>
            )}
            {!projectId && (
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="submit"
                >
                  Crear&nbsp;
                  <FaSave />
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="button"
                  onClick={() => navigate("/projects")}
                >
                  Volver al Listado&nbsp;
                  <FaBackward />
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default FormularioProyecto;
