import React from 'react';
import Navbar from '../../components/layout/Navbar';
const TerminosDeServicio = () => {
  return (
    <div>
      <Navbar />
      <div className="px-8 pt-6 pb-8 mb-4">
        <h1 className="text-2xl font-bold mb-4">Términos de Servicio de View Mineral</h1>
        <div className="text-gray-700">
          <h2 className="text-xl font-semibold mt-4">1. Información General</h2>
          <p>
            View Mineral Ltda. ("View Mineral", "nosotros", "nos" o "nuestro") ofrece una aplicación móvil ("Aplicación") 
            y un sitio web ("Sitio web") que permite a los usuarios cuantificar la ley de cobre de muestras minerales ("Servicios"). 
            Estos Términos de servicio ("Términos") regulan el uso de los Servicios.
          </p>

          <h2 className="text-xl font-semibold mt-4">2. Aceptación de los Términos</h2>
          <p>
            Al acceder o utilizar cualquier parte de los Servicios usted acepta estar sujeto a estos Términos. 
            Si no está de acuerdo con todos los términos y condiciones de este acuerdo no puede acceder ni utilizar 
            ninguno de nuestros Servicios.
          </p>

          <h2 className="text-xl font-semibold mt-4">3. Elegibilidad del Usuario</h2>
          <p>
            Debe ser mayor de edad en su jurisdicción para usar los Servicios. No puede usar los Servicios para 
            ningún propósito ilegal o no autorizado.
          </p>

          <h2 className="text-xl font-semibold mt-4">4. Cuentas de Usuario</h2>
          <p>
            Es posible que se le solicite crear una cuenta para utilizar determinadas funciones de los Servicios. 
            Usted es responsable de mantener la confidencialidad de la información de su cuenta y de todas las actividades 
            que ocurran bajo su cuenta. Acepta notificarnos de inmediato cualquier uso no autorizado de su cuenta.
          </p>

          <h2 className="text-xl font-semibold mt-4">5. Contenido</h2>
          <p>
            Los Servicios pueden contener contenido que no es propiedad de View Mineral. Dicho contenido es propiedad de 
            sus respectivos propietarios y puede estar protegido por derechos de autor, marcas comerciales u otras leyes 
            de propiedad intelectual. No puede usar ningún contenido en los Servicios sin el permiso expreso de su propietario.
          </p>

          <h2 className="text-xl font-semibold mt-4">6. Conducta del Usuario</h2>
          <p>Acepta usar los Servicios de manera consistente con estos Términos y todas las leyes y regulaciones aplicables. Acepta no:</p>
          <ul className="list-disc list-inside ml-4">
            <li>Usar los Servicios para cualquier propósito ilegal o no autorizado.</li>
            <li>Usar los Servicios para violar cualquier ley o regulación.</li>
            <li>Transmitir cualquier publicidad no solicitada o no autorizada, materiales promocionales o cualquier otra forma de solicitud.</li>
            <li>Intentar acceder o usar los Servicios sin autorización.</li>
            <li>Interferir o interrumpir los Servicios o el uso de los Servicios por parte de cualquier otro usuario.</li>
            <li>Hacerse pasar por cualquier persona o entidad.</li>
            <li>Transmitir cualquier código dañino o malicioso.</li>
            <li>Recopilar o almacenar información personal sobre otros usuarios sin su consentimiento.</li>
          </ul>

          <h2 className="text-xl font-semibold mt-4">7. Modificaciones a los Servicios</h2>
          <p>
            Nos reservamos el derecho de modificar o descontinuar los Servicios o cualquier parte de los mismos en cualquier 
            momento sin previo aviso. No seremos responsables ante usted ni ante ningún tercero por cualquier modificación, 
            suspensión o descontinuación de los Servicios.
          </p>

          <h2 className="text-xl font-semibold mt-4">8. Tarifas</h2>
          <p>
            Podemos cobrar tarifas por el uso de ciertas funciones de los Servicios. Todas las tarifas no son reembolsables 
            a menos que se indique lo contrario.
          </p>

          <h2 className="text-xl font-semibold mt-4">9. Contenido Generado por el Usuario</h2>
          <p>
            Puede enviar contenido a los Servicios como reseñas, calificaciones y comentarios ("Contenido generado por el usuario"). 
            Usted conserva la propiedad de su Contenido generado por el usuario pero nos otorga una licencia no exclusiva, mundial 
            y libre de regalías para usar, reproducir, modificar, distribuir y mostrar su Contenido generado por el usuario en relación 
            con los Servicios. Usted acepta que su Contenido generado por el usuario no será ilegal, obsceno, difamatorio, 
            amenazante, invasivo de la privacidad u objetable de otra manera.
          </p>

          <h2 className="text-xl font-semibold mt-4">10. Política de Privacidad</h2>
          <p>
            Nuestra Política de privacidad rige su uso de los Servicios. Puede ver nuestra Política de privacidad en &nbsp; 
            <a href="/project/PoliticaDePrivacidad" className="text-blue-500 hover:underline">Politica de Privacidad</a>
          </p>

          <h2 className="text-xl font-semibold mt-4">11. Propiedad Intelectual</h2>
          <p>
            Todos los derechos de propiedad intelectual sobre los Servicios, incluidos, entre otros, los derechos de autor, 
            las marcas comerciales, las patentes y los secretos comerciales, son propiedad de View Mineral o sus licenciantes. 
            No puede usar ninguna propiedad intelectual de View Mineral o sus licenciantes sin nuestro permiso expreso por escrito.
          </p>

          <h2 className="text-xl font-semibold mt-4">12. Indemnización</h2>
          <p>
            Usted acepta indemnizar, defender y mantener a View Mineral, sus funcionarios, directores, empleados, agentes, 
            afiliados y licenciantes de y contra cualquier reclamo, pérdida, daño, responsabilidad, costo y gasto (incluidos 
            los honorarios razonables de abogados) que surjan de o en relación con su uso de los Servicios, su violación de 
            estos Términos o su violación de cualquier ley o regulación.
          </p>

          <h2 className="text-xl font-semibold mt-4">13. Limitación de Responsabilidad</h2>
          <p>
            View Mineral, sus funcionarios, directores, empleados, agentes, afiliados o licenciatarios no serán responsables en 
            ningún caso por ningún tipo de daño, ya sea directo, indirecto, incidental, especial, consecuencial o ejemplar, 
            incluyendo pero no limitado a:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>Pérdidas de ganancias, ingresos, ahorros o datos.</li>
            <li>Pérdidas derivadas de la interrupción del negocio o la imposibilidad de utilizar los Servicios.</li>
            <li>Pérdidas por cualquier reclamo de terceros.</li>
            <li>Costos de sustitución de bienes o servicios.</li>
            <li>Costos de adquisición de bienes o servicios de reemplazo.</li>
            <li>Daños y perjuicios resultantes de cualquier transacción realizada a través de los Servicios o de cualquier contenido o información proporcionada a través de los Servicios.</li>
            <li>Cualquier daño especial, incidental o consecuente.</li>
            <li>Cualquier daño que surja de cualquier manera del uso de los Servicios o de cualquier contenido o información proporcionada a través de los Servicios, incluso si se ha advertido a View Mineral de la posibilidad de tal daño.</li>
          </ul>
          <p>
            Esta limitación de responsabilidad se aplicará independientemente de la base de la responsabilidad, ya sea contractual, 
            extracontractual o de estricta responsabilidad, incluso si View Mineral ha sido advertido de la posibilidad de tal daño.
          </p>
          <p>
            Algunas jurisdicciones no permiten la exclusión o limitación de responsabilidad por daños consecuenciales o incidentales, 
            por lo que la limitación anterior puede no aplicarse a usted.
          </p>
          <p>
            En ningún caso la responsabilidad total de View Mineral por cualquier daño que surja de o esté relacionado con el uso de 
            los Servicios o de cualquier contenido o información proporcionada a través de los Servicios excederá la cantidad pagada 
            por usted por el uso de los Servicios.
          </p>
          <p>
            Es importante tener en cuenta que esta limitación de responsabilidad es una parte esencial de estos Términos de Servicio. 
            Al usar los Servicios, usted acepta esta limitación de responsabilidad.
          </p>
          <p>
            Le recomendamos que lea atentamente esta limitación de responsabilidad y que se ponga en contacto con un abogado si tiene 
            alguna pregunta.
          </p>
          <p>
            Además de las limitaciones anteriores, View Mineral no será responsable de ningún daño que surja de:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>El uso de los Servicios en violación de estos Términos de Servicio.</li>
            <li>La confianza en cualquier información o contenido proporcionado a través de los Servicios.</li>
            <li>Cualquier modificación o alteración de los Servicios por parte de terceros.</li>
            <li>Cualquier falla o interrupción de los Servicios que sea causada por un evento fuera del control razonable de View Mineral.</li>
          </ul>
          <p>
            View Mineral se reserva el derecho de modificar esta limitación de responsabilidad en cualquier momento. 
            Las modificaciones entrarán en vigor al momento de su publicación en el Sitio web o en la Aplicación.
          </p>
          <p>
            Su uso continuo de los Servicios después de la publicación de cualquier modificación a esta limitación de responsabilidad 
            constituirá su aceptación de dicha modificación.
          </p>
          <p>
            Si tiene alguna pregunta sobre esta limitación de responsabilidad, por favor contáctenos en <a href="mailto:info@viewmineral.cl" className="text-blue-500 hover:underline">info@viewmineral.cl</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TerminosDeServicio;
