// Este componente fue creado para mostrar las detecciones de
// minerales por capa en la imagen de entrada en el proceso de
// pruebas de campo
const cssStyleAbsoluteImagen =
  "absolute top-0 left-0 object-cover md:h-96 md:w-100 h-92";

const CapaImagen = ({ deteccion, index }) => {
  return (
    <img
      index={index}
      src={deteccion.img_inferencia}
      alt={deteccion.clase}
      class={cssStyleAbsoluteImagen}
    />
  );
};

function DeteccionPorCapa({ analisis, getFlagMineral }) {
  console.log("DeteccionPorCapa mostrando:", analisis);

  return (
    <div className="relative">
      <img
        src={analisis.link_imagen_entrada}
        alt={analisis.link_imagen_entrada}
        class={cssStyleAbsoluteImagen}
      />
      {analisis.detecciones &&
        analisis.detecciones.map((deteccion, index) => {
          if (getFlagMineral(deteccion.clase)) {
            return (
              <CapaImagen key={index} deteccion={deteccion} index={index} />
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default DeteccionPorCapa;
