import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

function LayoutPrivado() {
  return (
    <div className="bg-gray-100">
      <Navbar />

      <Outlet />
    </div>
  );
}

export default LayoutPrivado;
