import React, { useState } from "react";
import { FaBook } from "react-icons/fa";

const GuideButton = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {/* Botón flotante para la guía de uso */}
      <button
        onClick={toggleModal}
        className="fixed bottom-8 right-20 bg-green-500 hover:bg-green-600 text-white rounded-full p-3 shadow-md"
      >
        <FaBook className="w-6 h-6" />
      </button>

      {/* Modal de Guía de Uso */}
      {showModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-8 max-w-lg max-h-full overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">Guía de Uso</h2>
            <div className="overflow-y-scroll h-80">
              {/* Contenido de la Guía de Uso extraído del documento Word */}
              <ol className="list-decimal font-medium pl-4 space-y-4">
                <li>
                  Al entrar al View Mineral con su usuario y clave se desplazará la pantalla de inicio donde le mostrará los proyectos que ya ha generado y el botón “crear nuevo proyecto”.
                </li>
                <li>
                  Si usted quiere generar un nuevo proyecto de análisis debe presionar el botón “Crear un nuevo proyecto”.
                  <img src="guide/Imagen_0.png" alt="Crear nuevo proyecto" className="my-4" />
                </li>
                <li>
                  Al presionar el botón se desplegará la pantalla con un formulario para la creación del proyecto donde debe llenar dos datos: “Nombre” que se refiera al nombre con el que identificará el proyecto y “Descripción” que se refiera a cualquier información explicativa que sea de utilidad para la propia identificación del usuario.
                </li>
                <li>
                  Posterior al llenado de estos campos debe presionar el botón “Crear”.
                  <img src="guide/Imagen_12.png" alt="Botón Crear" className="my-4" />
                </li>
                <li>
                  Una vez presionado el botón indicado en el punto anterior, se dirigirá a la pantalla principal de su proyecto donde verá 3 botones importantes: “Análisis”, “Editar” y “Eliminar”.
                  
                </li>
                <li>
                  Para iniciar un análisis de una muestra en un proyecto que no tiene datos, debe presionar el botón “Análisis”.
                  <img src="guide/Imagen_1.png" alt="Botón Análisis" className="my-4" />
                </li>
                <li>
                  Al presionar el botón indicado, se abrirá la funcionalidad del sistema para tomar la muestra a través de una fotografía o bien “subir” una imagen desde el dispositivo móvil.
                  
                </li>
                <li>
                  Lo primero será que View Mineral pedirá autorización para el uso de la cámara a lo que debe presionar el botón “permitir” o similar.
                </li>
                <li>
                  Una vez realizado el paso anterior, se abrirá la cámara dentro de View Mineral y aparecerán dos mensajes sobre la ventana de la cámara: el primero en una franja roja que indica si la luz para la toma de la foto es la adecuada que cambiará a verde cuando la cantidad de luz sea correcta. Otra en celeste que indica si la distancia para la toma de la muestra es la adecuada. Para obtener la información de este último punto deberá poner el dorso de su mano frente a la cámara y se le indicará el mensaje de distancia si es adecuada o no.
                  
                </li>
                <li>
                  Para tomar la muestra debe presionar el botón que tiene la imagen de una cámara.
                  <img src="guide/Imagen_3.png" alt="Botón cámara" className="my-4" />
                </li>
                <li>
                  La alternativa a los puntos 9 y 10 de esta guía es subir una imagen ya tomada o archivada en el dispositivo para lo cual debe presionar el botón “Desde Galería”.
                  <img src="guide/Imagen_4.png" alt="esde Galeria" className="my-4" />
                </li>
                <li>
                  Luego de tomar la fotografía, View Mineral mostrará la imagen fotografiada e indicará 2 botones: “Repetir Captura” y “Analizar”.
                  <img src="guide/Imagen_5.png" alt="Botones Repetir Captura" className="my-4" />
                  <img src="guide/Imagen_6.png" alt="Botones Analizar" className="my-4" />
                </li>
                <li>
                  Para generar el análisis debe presionar el botón “Analizar” con esto View Mineral comenzará a analizar; debe esperar hasta que el sistema entregue el resultado.
                </li>
                <li>
                  Una vez que View Mineral termina el análisis, mostrará la imagen con los sectores identificados y un listado de los minerales detectados y su ley de cobre asociada. Por otro lado, existen dos botones adicionales: “Ver” que permite al usuario “encender” o “apagar” la detección asociada al mineral, y “Prueba de Campo” donde hay un botón “Ir” en cada detección que llevará al usuario a una guía para ejecutar una prueba de campo en caso que se requiera.
                </li>
                <li>
                  Además, en esta pantalla de resultados existen dos botones en la parte inferior que son “Ir al Proyecto” y “Añadir otra muestra”.
                  <img src="guide/Imagen_8.png" alt="Botones Ir al Proyecto" className="my-4" />
                  <img src="guide/Imagen_9.png" alt="Añadir Nuevo" className="my-4" />
                </li>
                <li>
                  “Ir al Proyecto” permite al usuario volver a la página principal del proyecto y ver la ley de cobre de la muestra en su conjunto.
                </li>
                <li>
                  “Añadir Muestra” permite sumar una segunda o más imágenes de la misma muestra para tener un resultado con más ángulos de la misma muestra. En este caso se repiten los pasos desde el punto 9 al 14 de esta guía de uso.
                </li>
                <li>
                  Al aplicar el punto 16 de esta guía de uso se concluye el proceso de análisis y el usuario obtendrá un resultado de Ley de Cobre.
                </li>
                <li>
                  Si el usuario quiere añadir nuevas imágenes de la muestra puede hacerlo pinchando el botón “Editar” y si quiere eliminar el proyecto debe presionar el botón “Eliminar”.
                  <img src="guide/Imagen_10.png" alt="Botones Editar" className="my-4" />
                  <img src="guide/Imagen_11.png" alt="Botones Eliminar" className="my-4" />
                </li>
              </ol>
            </div>
            <button
              onClick={toggleModal}
              className="mt-6 bg-gray-600 hover:bg-gray-800 text-white rounded px-4 py-2"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default GuideButton;
