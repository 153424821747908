import { useState, useEffect } from "react";
import axios from "axios";
import {
  FaEdit,
  FaList,
  FaUser,
  FaUserAltSlash,
  FaUserCheck,
  FaUserInjured,
} from "react-icons/fa";

const isFechaExpirada = (fecha) => {
  const fechaActual = new Date();
  const fechaExpiracion = new Date(fecha);
  return fechaExpiracion < fechaActual;
};

const MantenedorUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);

  const obtenerUsuarios = async () => {
    setLoading(true);
    let url = "https://kong.viewmineral.cl/persistencia/usuarios/get-users";
    let headers = {
      Authorization: localStorage.getItem("jwt"),
    };
    axios
      .get(url, { headers })
      .then((response) => {
        console.log(response.data);
        setUsuarios(response.data.usuarios);
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-semibold mb-6 mt-5 text-center">
        Mantenedor de Usuarios{" "}
        {usuarios.length > 0 && `(${usuarios.length} usuarios)`}
      </h2>

      {loading && <div> buscando usuarios... </div>}

      {/* lo mismo pero usando flex y divs */}
      <div className="flex gap-x-4 border ">
      <div className="min-w-0 flex-auto w-1 ">
          <p className=" text-sm font-semibold leading-6 text-gray-900">
            #
          </p>
        </div>
        <div className="min-w-0 flex-auto w-24 ">
          <p className=" text-sm font-semibold leading-6 text-gray-900">
            Nombre
          </p>
        </div>
        <div className="min-w-0 flex-auto w-24 hidden lg:block">
          <p className="text-sm font-semibold leading-6 text-gray-900">Email</p>
        </div>
        <div className="min-w-0 flex-auto w-24   hidden md:block ">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            Empresa
          </p>
        </div>
        <div className="min-w-0 flex-auto w-24 hidden lg:block">
          <p className="text-sm font-semibold leading-6 text-gray-900">ROL</p>
        </div>
        <div className="min-w-0 flex-auto w-24 hidden lg:block">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            Expiración
          </p>
        </div>
        <div className="min-w-0 flex-auto w-24 ">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            Acciones
          </p>
        </div>
      </div>

      {usuarios.map((usuario, index) => (
        <div key={usuario._id} className="flex items-center gap-x-4 border">
          <div className="min-w-0 flex-auto w-1">
            <p className="text-sm">{index + 1}</p>
          </div>

          <div className="min-w-0 flex-auto w-24 ">
            <p className="text-sm">
              <div
                className={
                  isFechaExpirada(usuario.expiracion)
                    ? "text-red-500"
                    : "text-emerald-500"
                }
              >
                {usuario.nombre + " " + usuario.apellido}
              </div>
            </p>
            <p className="text-sm text-gray-900 visible sm:invisible ">{usuario.empresa}</p>
          </div>
          <div className="min-w-0 flex-auto w-24  hidden lg:block ">
            <p className="text-sm text-gray-900">{usuario.email}</p>
          </div>
          <div className="min-w-0 flex-auto w-24   hidden md:block ">
            <p className="text-sm text-gray-900">{usuario.empresa}</p>
          </div>
          <div className="min-w-0 flex-auto w-24 hidden lg:block">
            <p className="text-sm text-gray-900">{usuario.role}</p>
          </div>
          <div className="min-w-0 flex-auto w-24 hidden lg:block">
            {isFechaExpirada(usuario.expiracion) && (
              <>
                <p className="text-xs leading-5 text-red-500">
                  {usuario.expiracion}
                </p>
              </>
            )}
            {!isFechaExpirada(usuario.expiracion) && (
              <>
                <p className="text-xs leading-5 text-emerald-500">
                  {usuario.expiracion}
                </p>
              </>
            )}
          </div>
          <div className="min-w-0 flex-auto w-24 ">
            <div className="flex items-center gap-x-1.5">
              <a
                href={`/projects/${usuario.username}`}
                className="text-blue-500 hover:text-blue-800"
                target="_blank"
                rel="noreferrer"
                
              >
                <FaList />
              </a>
              <a
                href={`/usuarios/editar/${usuario._id}`}
                className="text-blue-500 hover:text-blue-800"
              >
                <FaEdit />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MantenedorUsuarios;
