import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useBuscarUsuarioLogeado = () => {
  const [usuario, setUsuario] = useState(null);
  const [mensajeValidacion, setMensajeValidacion] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const persistirUsuarioLogeado = (usuarioLogueado, jwt) => {
    setUsuario(usuarioLogueado);
    localStorage.setItem("user", JSON.stringify(usuarioLogueado));
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("username", usuarioLogueado.username);
  };

  const limpiarUsuarioLogeado = () => {
    setUsuario(null);
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
  };

  const validarUsuarioLogeado = async () => {
    setLoading(true);
    try {
      const jwt = localStorage.getItem("jwt");

      // If there is no JWT, the user is not logged in
      if (!jwt) {
        return;
      }

      if (jwt) {
        // Aquí debes hacer la llamada a la API para buscar el usuario usando el JWT
        // Puedes usar fetch u otra librería como axios
        const url_get_user =
          "https://kong.viewmineral.cl/persistencia/usuarios/get-user";
        const response = await fetch(url_get_user, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        // 401 case
        if (response.status === 401) {
          setMensajeValidacion("Usuario no logueado");
          alert(
            "Usuario no logueado, posiblemente la sesión ha expirado. Por favor, inicia sesión nuevamente."
          );
          limpiarUsuarioLogeado();
          return;
        }

        const data = await response.json();

        if (data.codigo === 0) {
          persistirUsuarioLogeado(data.usuario, jwt);
          setMensajeValidacion("Usuario logueado: " + data.usuario.username);
        } else {
          setUsuario(null);
          setMensajeValidacion(data.mensaje);
          alert(data.mensaje);
        }
      }
    } catch (error) {
      setUsuario(null);
      console.error("Error al buscar el usuario logueado:", error);
      setMensajeValidacion("Error al buscar el usuario logueado");
    }
    setLoading(false);
  };


  const login = async (username, password) => {
    setLoading(true);
    try {
      // const url_login = "http://localhost:8000/usuarios/login";
      const url_login =
        "https://kong.viewmineral.cl/persistencia/usuarios/login";
      const response = await fetch(url_login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.codigo === 0) {
          // Logging API response
          console.log("Respuesta de la API:", data.mensaje);
          // Storing data in localStorage
          localStorage.setItem("username", data.usuario.username);
          localStorage.setItem("jwt", data.jwt);
          localStorage.setItem("user", JSON.stringify(data.usuario));
          // Navigate to home page after successful login
          navigate("/projects");
        } else {
          console.error("Login failed: Invalid credentials");
          setMensajeValidacion(data.mensaje);
        }
      } else {
        console.error("Login failed: Invalid credentials");
        setMensajeValidacion(
          "Credenciales inválidas. Por favor, verifica tu nombre de usuario y contraseña."
        );
      }
    } catch (error) {
      setMensajeValidacion(
        "Ocurrió un error durante el inicio de sesión. Por favor, inténtalo de nuevo más tarde."
      );
      console.info(error);
    }
    setLoading(false);
  };

  return { usuario, mensajeValidacion, validarUsuarioLogeado, login, loading };
};

export default useBuscarUsuarioLogeado;
