import { IconContext } from "react-icons";

const NavbarPublica = ({ logout }) => {
  return (
    <div className="sticky top-0 bg-gray-800 z-10">
      <div className="headerTop">
        <IconContext.Provider
          value={{
            color: "white",
            size: "1.5em",
            className: "global-class-name",
          }}
        >
          <div className="topItem">
            <li> </li>
          </div>
          <div className="topItem">
            <a href="/">
              <img className="opacity-85" src="/img/logo2022.png" alt="Logo" />
            </a>
          </div>
          <div className="topItem">
            <li></li>
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default NavbarPublica;
