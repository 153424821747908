import { useState, useEffect } from "react";
import axios from "axios";

const usePaises = () => {
  const [paises, setPaises] = useState([]);
  const [loading, setLoading] = useState(true);

  const buscarPaisPorCodigo = (codigo) => {
    return paises.find((pais) => pais.code === codigo);
  }

  useEffect(() => {
    const fetchPaises = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://kong.viewmineral.cl/persistencia/paises/todos");
        setPaises(response.data.data);
      } catch (error) {
        console.error("Error al obtener los países:", error);
      }
      setLoading(false);
    };

    fetchPaises();
  }, []);

  return { paises, loading, buscarPaisPorCodigo };
};

export default usePaises;
